import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Stack } from "@mui/material";
import employee1 from "../assets/images/accordion/employee1.png";
import employee2 from "../assets/images/accordion/employee2.png";
import employee3 from "../assets/images/accordion/employee3.png";
import employer1 from "../assets/images/accordion/employer1.png";
import employer2 from "../assets/images/accordion/employer2.png";
import employer3 from "../assets/images/accordion/employer3.png";
import merchant1 from "../assets/images/accordion/merchant1.png";
import merchant2 from "../assets/images/accordion/merchant2.png";
import merchant3 from "../assets/images/accordion/merchant3.png";
import step1icon from "../assets/images/step1icon.png";
import step2icon from "../assets/images/step2icon.png";
import step3icon from "../assets/images/step3icon.png";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
    border: `0px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&::before": {
        display: "none",
    },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
    ({ theme }) => ({
        paddingRight: "30px",
        paddingLeft: "30px",
        flexDirection: "row-reverse",
    })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    paddingBottom: "0px",
    paddingLeft: "30px",
}));

export default function StepsAccordion() {
    const [expanded, setExpanded] = useState("panel1");
    const { t } = useTranslation();

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : "panel1");
    };
    let path = window.location.pathname;
    const primary = path === "/merchants" ? "primary.dark" : "primary.main";
    let prefix = "";
    let images = [];
    switch (path) {
        case "/employers":
            prefix = "employer";
            images = [employer1, employer2, employer3];
            break;
        case "/merchants":
            prefix = "merchant";
            images = [merchant1, merchant2, merchant3];

            break;
        default:
            prefix = "employee";
            images = [employee1, employee2, employee3];
            break;
    }

    return (
        <Box mb={8}>
            <Typography
                textAlign={"center"}
                variant="h5"
                fontWeight={600}
                mb={4}
            >
                {t("steps.title")}
            </Typography>
            <Box
                sx={{
                    maxWidth: "600px",
                    margin: "auto",
                }}
                mt={3}
            >
                <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                >
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                    >
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Box
                                sx={{
                                    borderRadius: "8px",
                                    backgroundColor: "tertiary.main",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "6px",
                                }}
                                mr={1}
                            >
                                <img src={step1icon} width="24px" alt="step1" />
                            </Box>
                            <Typography fontWeight={600}>
                                {t(`steps.${prefix}.step1.title`)}
                            </Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography>
                                    {t(`steps.${prefix}.step1.content`)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <img
                                    style={{
                                        marginLeft: "-40px",
                                        marginBottom: "-4px",
                                        marginTop: "-20px",
                                    }}
                                    src={images[0]}
                                    width="340px"
                                    alt="step1-phone"
                                ></img>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                    sx={{ backgroundColor: "secondary.main" }}
                >
                    <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                        sx={{ color: primary }}
                    >
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Box
                                sx={{
                                    borderRadius: "8px",
                                    backgroundColor: primary,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "6px",
                                }}
                                mr={1}
                            >
                                <img src={step2icon} width="24px" alt="step" />
                            </Box>
                            <Typography fontWeight={600}>
                                {t(`steps.${prefix}.step2.title`)}
                            </Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography>
                                    {t(`steps.${prefix}.step2.content`)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <img
                                    style={{
                                        marginLeft: "-40px",
                                        marginBottom: "-4px",
                                        marginTop: "-20px",
                                    }}
                                    src={images[1]}
                                    width="340px"
                                    alt="step2-phone"
                                ></img>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    sx={{ backgroundColor: primary, color: "paper.main" }}
                >
                    <AccordionSummary
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                    >
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Box
                                sx={{
                                    borderRadius: "8px",
                                    backgroundColor: "secondary.main",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "6px",
                                }}
                                mr={1}
                            >
                                <img src={step3icon} width="24px" alt="step" />
                            </Box>
                            <Typography fontWeight={600}>
                                {t(`steps.${prefix}.step3.title`)}
                            </Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography>
                                    {t(`steps.${prefix}.step3.content`)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <img
                                    style={{
                                        marginLeft: "-40px",
                                        marginBottom: "-4px",
                                        marginTop: "-30px",
                                    }}
                                    src={images[2]}
                                    width="340px"
                                    alt="step3-phone"
                                ></img>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );
}
