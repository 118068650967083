import { ListItem, Typography, Link } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FlightsPolicy from "./FlightsPolicy";
import HotelsPolicy from "./HotelsPolicy";

export const BulletPoint = ({ text }) => (
    <ListItem sx={{ display: "list-item" }}>
        <Typography>{text}</Typography>
    </ListItem>
);

const RefundPolicy = () => {
    const location = useLocation();
    const [product, setProduct] = useState("");

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const productParam = searchParams.get("product");
        setProduct(productParam);
    }, [location.search]);

    return (
        <Box py={2} px={{ xs: 2, md: 1 }}>
            <Container maxWidth={"xl"}>
                <Typography
                    variant="h4"
                    fontWeight={600}
                    my={5}
                    textAlign={"center"}
                >
                    {product === "flights" && "Flights Booking Policy"}
                    {product === "hotels" && "Hotels Booking Policy"}
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                    my={5}
                    textAlign={"center"}
                >
                    {!product && (
                        <>
                            <Typography
                                variant="h4"
                                fontWeight={600}
                                my={5}
                                textAlign={"center"}
                            >
                                Third Party Refund Policy
                            </Typography>
                            <Typography color="primary">
                                <Link href="?product=flights" underline="hover">
                                    Flights Booking Policy
                                </Link>
                            </Typography>
                            <Typography color="primary">
                                <Link href="?product=hotels" underline="hover">
                                    Hotels Booking Policy
                                </Link>
                            </Typography>
                        </>
                    )}
                </Box>
            </Container>

            {product === "flights" && <FlightsPolicy />}
            {product === "hotels" && <HotelsPolicy />}
        </Box>
    );
};

export default RefundPolicy;
