import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import i18n from "./i18n";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar/Navbar";
import Reimbursement from "./pages/Reimbursement";
import Edocs from "./pages/Edocs";
import Vouchers from "./pages/Vouchers";
import Salary from "./pages/Salary";
import TimeTracking from "./pages/TimeTracking";
import FAQ from "./pages/FAQ";
import Employees from "./pages/Employees";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Employers from "./pages/Employers";
import Merchants from "./pages/Merchants";
import NotFound from "./pages/NotFound";
import Privacy from "./pages/Privacy";
import RefundPolicy from "./pages/refundPolicy/RefundPolicy";

function App() {
    return (
        <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Navbar page={<Home />} />} />
                        <Route
                            path="/reimbursements"
                            element={<Navbar page={<Reimbursement />} />}
                        />
                        <Route
                            path="/evouchers"
                            element={<Navbar page={<Vouchers />} />}
                        />
                        <Route
                            path="/salaryadvances"
                            element={<Navbar page={<Salary />} />}
                        />
                        <Route
                            path="/timetracking"
                            element={<Navbar page={<TimeTracking />} />}
                        />
                        <Route
                            path="/edocuments"
                            element={<Navbar page={<Edocs />} />}
                        />
                        <Route
                            path="/employees"
                            element={<Navbar page={<Employees />} />}
                        />
                        <Route
                            path="/employers"
                            element={<Navbar page={<Employers />} />}
                        />
                        <Route
                            path="/merchants"
                            element={<Navbar page={<Merchants />} />}
                        />
                        <Route
                            path="/faq"
                            element={<Navbar page={<FAQ />} />}
                        />
                        <Route
                            path="/privacy"
                            element={<Navbar page={<Privacy />} />}
                        />
                        <Route
                            path="/third-party/refund-policy"
                            element={<Navbar page={<RefundPolicy />} />}
                        />
                        <Route
                            path="*"
                            element={<Navbar page={<NotFound />} />}
                        />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </I18nextProvider>
    );
}

export default App;
