import React, { useEffect } from "react";
import { Container, Box, Grid, Typography, ImageListItem } from "@mui/material";
import bannerimgEN from "../assets/images/dashboard.png";
import bannerimgFR from "../assets/images/dashboardFR.png";
import section1 from "../assets/images/employers_s1.png";
import section2 from "../assets/images/employers_s2.png";
import section3 from "../assets/images/employers_s3.png";
import section4 from "../assets/images/employers_s4.png";
import section5 from "../assets/images/employers_s5.png";
import RoundButton from "../components/Buttons/RoundButton";
import { useTranslation } from "react-i18next";
import ContactUs from "../components/ContactUs/ContactUs";
import StepsAccordion from "../components/StepsAccordion";
import { HashLink } from "react-router-hash-link";
import AppBanner from "../components/AppBanner/AppBanner";

function Employers() {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <Box>
            <Box
                sx={{
                    backgroundColor: "primary.main",
                    color: "#fff",
                    minHeight: "25rem",
                    overflowX: "hidden",
                    px: { xs: 1, md: 0 },
                }}
            >
                <Container maxWidth={"xl"}>
                    <Grid container spacing={2} px={2}>
                        <Grid
                            item
                            xs={0}
                            md={1}
                            sx={{
                                paddingBottom: {
                                    xs: 2,
                                    md: 10,
                                },
                            }}
                        ></Grid>
                        <Grid
                            item
                            xs={12}
                            md={5}
                            sx={{
                                paddingBottom: {
                                    xs: 2,
                                    md: 10,
                                },
                            }}
                        >
                            <Typography
                                variant="h4"
                                fontWeight={600}
                                mb={2}
                                mt={{ xs: 6, md: 10 }}
                            >
                                {t("employers.banner.maintext")}
                            </Typography>
                            <Typography fontSize={"16px"} mb={4}>
                                {t("employers.banner.subtext")}
                            </Typography>
                            <HashLink smooth to={"#contact-us"}>
                                <RoundButton
                                    size={"small"}
                                    disableElevation
                                    text={t("employers.banner.button")}
                                    variant="contained"
                                    color={"paper"}
                                    sx={{ color: "primary.main" }}
                                />
                            </HashLink>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: { xs: "center", md: "start" },
                                alignItems: "center",
                                marginTop: { xs: 1, md: 5 },
                            }}
                        >
                            <ImageListItem>
                                <img
                                    src={
                                        i18n.language === "en"
                                            ? bannerimgEN
                                            : bannerimgFR
                                    }
                                    alt="banner"
                                />
                            </ImageListItem>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Container maxWidth={"xl"}>
                <Grid
                    container
                    spacing={2}
                    px={2}
                    mt={4}
                    mb={8}
                    alignItems="center"
                >
                    <Grid
                        item
                        order={{ xs: 2, md: 1 }}
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ImageListItem sx={{ padding: 3, maxWidth: "550px" }}>
                            <img src={section1} alt="banner" />
                        </ImageListItem>
                    </Grid>
                    <Grid item order={{ xs: 1, md: 2 }} xs={12} md={5} px={2}>
                        <Typography
                            fontSize={"15px"}
                            color={"primary"}
                            mb={4}
                            mt={3}
                        >
                            {t("employers.section1.advancepayment")}
                        </Typography>
                        <Typography variant="h5" fontWeight={600} mb={4}>
                            {t("employers.section1.maintext")}
                        </Typography>
                        <Typography fontSize={"16px"} mb={4}>
                            {t("employers.section1.subtext")}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            <Container
                maxWidth={"lg"}
                sx={{ backgroundColor: "secondary.main", borderRadius: "10px" }}
            >
                <Grid container spacing={2} mb={4} px={2}>
                    <Grid item xs={12} md={6}>
                        <Typography
                            fontSize={"15px"}
                            color={"primary"}
                            mb={4}
                            mt={2}
                        >
                            {t("employers.section2.reimbursement")}
                        </Typography>
                        <Typography variant="h5" fontWeight={600} mb={4}>
                            {t("employers.section2.maintext")}
                        </Typography>
                        <Typography fontSize={"16px"} mb={4}>
                            {t("employers.section2.subtext")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                        }}
                    >
                        <ImageListItem sx={{ maxWidth: "400px" }}>
                            <img src={section2} alt="banner" />
                        </ImageListItem>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth={"xl"}>
                <Grid container spacing={2} mb={8} px={2} alignItems="center">
                    <Grid
                        item
                        order={{ xs: 2, md: 1 }}
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ImageListItem sx={{ padding: 3, maxWidth: "600px" }}>
                            <img src={section3} alt="banner" />
                        </ImageListItem>
                    </Grid>
                    <Grid item order={{ xs: 1, md: 2 }} xs={12} md={5}>
                        <Typography
                            fontSize={"15px"}
                            color={"primary"}
                            mb={4}
                            mt={2}
                        >
                            {t("employers.section3.timetracking")}
                        </Typography>
                        <Typography variant="h5" fontWeight={600} mb={4}>
                            {t("employers.section3.maintext")}
                        </Typography>
                        <Typography fontSize={"16px"} mb={4}>
                            {t("employers.section3.subtext")}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            <Container
                maxWidth={"lg"}
                sx={{ backgroundColor: "secondary.main", borderRadius: "10px" }}
            >
                <Grid container spacing={2} mb={8} px={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Typography
                            fontSize={"15px"}
                            color={"primary"}
                            mb={4}
                            mt={2}
                        >
                            {t("employers.section4.evouchers")}
                        </Typography>
                        <Typography variant="h5" fontWeight={600} mb={4}>
                            {t("employers.section4.maintext")}
                        </Typography>
                        <Typography fontSize={"16px"} mb={{ xs: 0, md: 4 }}>
                            {t("employers.section4.subtext")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "end",
                        }}
                    >
                        <ImageListItem sx={{ padding: 3, maxWidth: "450px" }}>
                            <img src={section4} alt="banner" />
                        </ImageListItem>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth={"xl"}>
                <Grid container spacing={2} mb={4} px={2} alignItems="center">
                    <Grid
                        item
                        order={{ xs: 2, md: 1 }}
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ImageListItem sx={{ maxWidth: "450px" }}>
                            <img src={section5} alt="pic" />
                        </ImageListItem>
                    </Grid>
                    <Grid item order={{ xs: 1, md: 2 }} xs={12} md={5}>
                        <Typography
                            fontSize={"15px"}
                            color={"primary"}
                            mb={4}
                            mt={2}
                        >
                            {t("employers.section5.hint")}
                        </Typography>
                        <Typography variant="h5" fontWeight={600} mb={4}>
                            {t("employers.section5.maintext")}
                        </Typography>
                        <Typography fontSize={"16px"} mb={4}>
                            {t("employers.section5.subtext")}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            <StepsAccordion />

            <ContactUs />
            <AppBanner />
        </Box>
    );
}

export default Employers;
