import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    typography: {
        fontFamily: "Urbanist",
        fontSize: 15,
    },
    palette: {
        primary: {
            main: "#347BF1",
            dark: "#002B69",
        },
        secondary: {
            main: "#D2E5FF",
        },
        tertiary: {
            main: "#EBEBEB",
        },
        merchant: {
            main: "#002B69",
        },
        background: {
            main: "#F8F8F8",
        },
        text: {
            main: "#000",
        },
        paper: {
            main: "#fff",
        },
        accordion: ["#fff", "#D2E5FF", "#347BF1"],
    },
});
