import { Box, Container, Grid, Typography } from "@mui/material";
import timetracking from "../../assets/images/timetracking.png";
import reimbursements from "../../assets/images/reimbrusment.png";
import evouchers from "../../assets/images/evoucher.png";
import salaryadvances from "../../assets/images/salaryadvance.png";
import edocs from "../../assets/images/edocs.png";
import RoundButton from "../Buttons/RoundButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Features = ({ hidden }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const features = [
        {
            name: "edocs",
            image: edocs,
        },
        {
            name: "timetracking",
            image: timetracking,
        },
        {
            name: "reimbursements",
            image: reimbursements,
        },
        {
            name: "evouchers",
            image: evouchers,
        },
        {
            name: "salaryadvances",
            image: salaryadvances,
        },
    ].filter((item) => item.name !== hidden[0] && item.name !== hidden[1]);
    return (
        <Box my={5}>
            <Container maxWidth={"lg"}>
                <Typography
                    variant="h5"
                    fontWeight={600}
                    textAlign={"center"}
                    mb={1}
                    mt={12}
                >
                    {t("features.title")}
                </Typography>
                <Grid container spacing={4} mt={3}>
                    {features.map((feature, index) => (
                        <Grid item xs={12} sm={4} key={index}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    src={feature.image}
                                    height={30}
                                    alt={"pic"}
                                />
                            </Box>
                            <Typography
                                fontSize={"18px"}
                                fontWeight={600}
                                textAlign={"center"}
                                my={1.5}
                            >
                                {t(`features.${feature.name}.maintext`)}
                            </Typography>
                            <Typography
                                fontSize={"16px"}
                                textAlign={"center"}
                                height={"7rem"}
                                mb={3}
                            >
                                {t(`features.${feature.name}.subtext`)}
                            </Typography>
                            <Box
                                mb={4}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <RoundButton
                                    size={"small"}
                                    disableElevation
                                    text={t("features.button")}
                                    variant="contained"
                                    color={"paper"}
                                    onClick={() => {
                                        navigate("/" + feature.name);
                                    }}
                                />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default Features;
