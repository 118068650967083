import { Container, Box, Grid, Typography, ImageListItem } from "@mui/material";
import bannerimgEN from "../assets/images/employeebannerEN.png";
import bannerimgFR from "../assets/images/employeebannerFR.png";
import phones from "../assets/images/phones.png";
import phones2 from "../assets/images/phones2.png";
import bill from "../assets/images/bill.png";
import bill2 from "../assets/images/bill2.png";
import s5 from "../assets/images/employee_s5.png";
import RoundButton from "../components/Buttons/RoundButton";
import { useTranslation } from "react-i18next";
import ContactUs from "../components/ContactUs/ContactUs";
import StepsAccordion from "../components/StepsAccordion";
import { HashLink } from "react-router-hash-link";
import AppBanner from "../components/AppBanner/AppBanner";
import { useEffect } from "react";

function Employees() {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <Box>
            <Box
                mb={3}
                sx={{
                    backgroundColor: "primary.main",
                    color: "#fff",
                    minHeight: "25rem",
                }}
            >
                <Container
                    disableGutters={{ xs: true, md: false }}
                    maxWidth={"xl"}
                >
                    <Grid container spacing={2} alignItems="center">
                        <Grid
                            item
                            xs={0}
                            md={1}
                            sx={{
                                paddingBottom: {
                                    xs: 2,
                                    md: 10,
                                },
                            }}
                        ></Grid>

                        <Grid
                            item
                            xs={12}
                            md={5}
                            mx={{ xs: 4, md: 0 }}
                            sx={{
                                paddingBottom: {
                                    xs: 2,
                                    md: 13,
                                },
                            }}
                        >
                            <Typography
                                variant="h4"
                                fontWeight={600}
                                mb={2}
                                mt={{ xs: 6, md: 10 }}
                            >
                                {t("employees.banner.maintext")}
                            </Typography>
                            <Typography fontSize={"16px"} mb={4}>
                                {t("employees.banner.subtext")}
                            </Typography>

                            <HashLink smooth to={"#contact-us"}>
                                <RoundButton
                                    size={"small"}
                                    disableElevation
                                    text={t("employees.banner.button")}
                                    variant="contained"
                                    color={"paper"}
                                    sx={{ color: "primary.main" }}
                                />
                            </HashLink>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: { xs: "center", md: "start" },
                                alignItems: "center",
                                marginTop: { xs: 1, md: 3 },
                            }}
                        >
                            <ImageListItem
                                sx={{
                                    marginLeft: { md: 4 },
                                    width: { md: "700px" },
                                }}
                            >
                                <img
                                    src={
                                        i18n.language === "en"
                                            ? bannerimgEN
                                            : bannerimgFR
                                    }
                                    alt="banner"
                                />
                            </ImageListItem>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Container maxWidth={"lg"}>
                <Grid container spacing={2} mb={8} alignItems="center">
                    <Grid
                        item
                        order={{ xs: 2, md: 1 }}
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ImageListItem sx={{ padding: 3, maxWidth: "550px" }}>
                            <img src={phones} alt={"pic"} />
                        </ImageListItem>
                    </Grid>
                    <Grid item order={{ xs: 1, md: 2 }} xs={12} md={6}>
                        <Typography
                            fontSize={"15px"}
                            color={"primary"}
                            mb={4}
                            mt={3}
                        >
                            {t("employees.section1.advancepayment")}
                        </Typography>
                        <Typography variant="h5" fontWeight={600} mb={4}>
                            {t("employees.section1.maintext")}
                        </Typography>
                        <Typography fontSize={"16px"} mb={4}>
                            {t("employees.section1.subtext")}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            <Container
                maxWidth={"lg"}
                sx={{ backgroundColor: "secondary.main", borderRadius: "10px" }}
            >
                <Grid container spacing={2} mb={8} px={2}>
                    <Grid item xs={12} md={6}>
                        <Typography
                            fontSize={"15px"}
                            color={"primary"}
                            mb={4}
                            mt={2}
                        >
                            {t("employees.section2.reimbursement")}
                        </Typography>
                        <Typography variant="h5" fontWeight={600} mb={4}>
                            {t("employees.section2.maintext")}
                        </Typography>
                        <Typography fontSize={"16px"} mb={4}>
                            {t("employees.section2.subtext")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "end",
                        }}
                    >
                        <ImageListItem sx={{ px: 3, maxWidth: "450px" }}>
                            <img src={bill} alt={"pic"} />
                        </ImageListItem>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth={"lg"}>
                <Grid container spacing={2} mb={8} alignItems="center">
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ImageListItem sx={{ padding: 3, maxWidth: "500px" }}>
                            <img src={phones2} alt={"pic"} />
                        </ImageListItem>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography
                            fontSize={"15px"}
                            color={"primary"}
                            mb={4}
                            mt={2}
                        >
                            {t("employees.section3.timetracking")}
                        </Typography>
                        <Typography variant="h5" fontWeight={600} mb={4}>
                            {t("employees.section3.maintext")}
                        </Typography>
                        <Typography fontSize={"16px"} mb={4}>
                            {t("employees.section3.subtext")}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            <Container
                maxWidth={"lg"}
                sx={{ backgroundColor: "secondary.main", borderRadius: "10px" }}
            >
                <Grid container spacing={2} mb={8} px={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Typography
                            fontSize={"15px"}
                            color={"primary"}
                            mb={4}
                            mt={2}
                        >
                            {t("employees.section4.evouchers")}
                        </Typography>
                        <Typography variant="h5" fontWeight={600} mb={4}>
                            {t("employees.section4.maintext")}
                        </Typography>
                        <Typography fontSize={"16px"} mb={4}>
                            {t("employees.section4.subtext")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "end",
                        }}
                    >
                        <img src={bill2} width={380} alt={"pic"} />
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth={"lg"}>
                <Grid container spacing={2} mb={8} alignItems="center">
                    <Grid
                        item
                        order={{ xs: 2, md: 1 }}
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ImageListItem sx={{ padding: 3, maxWidth: "450px" }}>
                            <img src={s5} alt={"pic"} />
                        </ImageListItem>
                    </Grid>
                    <Grid item order={{ xs: 1, md: 2 }} xs={12} md={6}>
                        <Typography
                            fontSize={"15px"}
                            color={"primary"}
                            mb={4}
                            mt={2}
                        >
                            {t("employees.section5.hint")}
                        </Typography>
                        <Typography variant="h5" fontWeight={600} mb={4}>
                            {t("employees.section5.maintext")}
                        </Typography>
                        <Typography fontSize={"16px"} mb={4}>
                            {t("employees.section5.subtext")}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            <StepsAccordion />

            <ContactUs />

            <AppBanner />
        </Box>
    );
}

export default Employees;
