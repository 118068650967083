import { Box, Container, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Privacy = () => {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <Box py={2}>
            <Container maxWidth={"xl"}>
                <Typography
                    variant="h4"
                    fontWeight={600}
                    my={5}
                    textAlign={"center"}
                >
                    {t("footer.legal.privacy")}
                </Typography>
            </Container>
            <Container maxWidth={"lg"}>
                <Box mb={4}>
                    <Typography variant="h6" fontWeight={600} mb={2}>
                        CONDITIONS GÉNÉRALES D’UTILISATION DE L’APPLICATION
                        GWALA CONCERNANT GWALA ET LES PRÉSENTES CONDITIONS
                        D’UTULISATION
                    </Typography>
                    <Typography>
                        L’Edition de l’application GWALA est assurée par la
                        Société GWALA MOROCCO , Société à Responsabilité Limitée
                        à Associé Unique (SARLAU) de droit Marocain, au capital
                        de 100.000,00 MAD, dont le siège social est situé à 13
                        Rue Ahmed El Majjati Rés. les Alples Etg 1 n°8
                        Casablanca Maroc immatriculée au registre de commerce de
                        Casablanca sous le numéro 557131. Les présentes
                        conditions générales d’utilisation s’appliquent
                        essentiellement aux utilisateurs de GWALA en leur
                        qualité de salarié Veuillez lire attentivement les
                        présentes Conditions générales d’utilisation qui
                        s'appliquent à votre inscription sur notre application.
                        Veuillez noter qu'avant toute adhésion, il vous sera
                        demandé d'accepter les présentes conditions
                        d’utilisations générales . Si vous n’acceptez pas les
                        présentes Conditions générales d’utilisation , vous ne
                        serez pas en mesure de bénéficier de nos services.
                    </Typography>
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" fontWeight={600} mb={2}>
                        1-CHAMP D’APPLICATION :
                    </Typography>
                    <Typography>
                        Les présentes conditions d’utilisation forment dans leur
                        ensemble le contrat qui organise et régule votre accès
                        ainsi que votre utilisation de l’application GWALA et
                        tous les sites web affiliés détenus et exploités par
                        GWALA , ceci comprend tous les sous-domaines , sous
                        répertoires , sites mobiles et applications mobiles de
                        ceux-ci. Le présent Contrat est conclu entre GWALA et
                        vous (l’«Utilisateur » le « Salarié »). Toute
                        inscription ou utilisation de l’application GWALA
                        implique l’acceptation sans aucune réserve ni
                        restriction des présentes CGU par l’Utilisateur.
                    </Typography>
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" fontWeight={600} mb={2}>
                        2-DEFINITIONS :
                    </Typography>
                    <Typography>
                        Les termes figurant dans le préambule et ailleurs dans
                        les présentes ont le sens qui leur est attribué, et
                        chacun des termes suivants a le sens qui lui est
                        attribué.Employé ou Utilisateur désigne l’adhérant
                        parfaitement inscrit au site GWALA ,c'est-à-dire le
                        consommateur désirant de bénéficier des acomptes sur
                        salaire.
                    </Typography>
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" fontWeight={600} mb={2}>
                        3-MENTIONS LEGALES :
                    </Typography>
                    <Typography>
                        L'hébergeur de l’application GWALA est la Société
                        Microsoft Maroc sis à 68, angle avenue Omar Ibn Khattab
                        et rue Oukaimeden Agdal -Rabat.
                    </Typography>
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" fontWeight={600} mb={2}>
                        4-À PROPOS DE L’APPLICATION GWALA :
                    </Typography>
                    <Typography>
                        Nous avons mis à votre disposition cette application
                        uniquement et de manière privé pour les employés
                        adhérents par la biais d’une convention rassemblant leur
                        employeur et la société GWALA afin de leur permettre de
                        bénéficier d’acomptes sur leur salaire jusqu’à 50% de ce
                        dernier ,sans que les frais de service dépassent 2% de
                        l’acompte désiré. Pour un usage plus facile et
                        confortable par ses utilisateurs GWALA déploie tous ses
                        efforts. GWALA déclare par ces présentes conditions
                        d’utilisation qu’elle est tenu d’une obligation de moyen
                        . Ainsi tout défaut se rapportant aux bugs informatiques
                        ou de dysfonctionnements liés aux connexions du réseau
                        et peut conduire à l’indisponibilité temporaire de
                        l’application GWALA. GWALA exempte sa responsabilité des
                        actes des utilisateurs qui sont contraires aux règles
                        arrêtées par les présentes et de tout dommage direct ou
                        indirect issu de l'utilisation de l’application GWALA
                        notamment en cas de problème technique, tels des virus,
                        ou des bugs informatique, une intrusion malveillante ou
                        un dysfonctionnement de l’application indépendant de sa
                        volonté. GWALA peut recourir en justice à tout moment
                        pour toute information malveillante ou fallacieuse sur
                        son application. Par conséquent GWALA n’est pas
                        responsable de tout dommage de quelque nature qu’il soit
                        résultant de l’interprétation ou de l’utilisation des
                        informations disponibles sur l’application.
                    </Typography>
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" fontWeight={600} mb={2}>
                        5-PROPRIÉTÉ INTELLECTUELLE :
                    </Typography>
                    <Typography>
                        Toutes les informations et éléments disponibles sur
                        l’application ainsi que les droits de propriété y
                        rattachés sont l’exclusive et à l’ unique propriété de
                        GWALA. L’application GWALA est constitué d’éléments
                        protégés par le droit d'auteur en tant qu'œuvre
                        collective en vertu des lois sur le droit d'auteur, des
                        marques de commerce et d'autres informations exclusives,
                        y compris, des textes, des logos, des logiciels, des
                        photos, des vidéos, des graphiques. Sans autorisation
                        écrite expresse de GWALA,il est interdit de copier,
                        transmettre , distribuer , afficher , modifier ou toute
                        autre démarche ayant un lien directe à l’application .
                        Par conséquent toute violation à la présente disposition
                        sera considérée comme une violation de la loi 02-00
                        relative aux droits d'auteur et droits voisins ou de
                        tout autre droit de propriété intellectuelle et
                        entrainera l’application de sanctions civiles et
                        pénales.
                    </Typography>
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" fontWeight={600} mb={2}>
                        6-RESPONSABILITÉ DES UTILISATEURS :
                    </Typography>
                    <Typography>
                        Les utilisateurs s’engagent à fournir des informations
                        véridiques lors de la finalisation du processus
                        d’inscription sur l’application et surtout en ce qui
                        concerne la copie de leur carte d’identité national.
                        Toute fraude ou falsification d’informations relatives à
                        votre compte sur l’application entrainera une
                        résiliation sans préavis du compte ainsi que
                        l’application de sanctions . Les utilisateurs de
                        l’application sont interdit d’agir de manière
                        malveillante qui se rapporte directement ou
                        indirectement contre les intérêts de GWALA. GWALA
                        détient le pouvoir d’appréciation de tout acte contraire
                        aux présentes CGU et ne manquera pas d’ester en justice
                        afin de réclamer ses droits .
                    </Typography>
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" fontWeight={600} mb={2}>
                        7-GUIDE D’UTILISATION DE L’APPLICATION :
                    </Typography>
                    <Typography>
                        Seul les utilisateurs (employé ) dont l’employeur a
                        conclu la convention GWALA ont le droit de bénéficier de
                        nos services. Le code de vérification envoyé par notre
                        service commercial a pour but d’activer votre compte sur
                        l’application , vous êtes dans l’obligation de ne pas le
                        communiquer à des tiers personnes . Dès lors que vous
                        communiquez votre carte d’identité national celle-ci
                        sera supprimé dès que la création de votre compte est
                        finalisée . Vous êtes responsable de toute information
                        fournie à GWALA , toute modification fait l’objet d’une
                        demande à notre service commercial . Vous ne pouvez
                        détenir qu’un seul compte dont vous êtes seul
                        responsable de sa gestion. Votre compte n'est pas
                        transférable et ne peut être vendu . Seul votre
                        employeur a le droit d’avoir des informations sur votre
                        solde à la fin du mois afin de déterminer le montant du
                        salaire à vous attribuer. Si vous enfreignez l'une de
                        ces limitations, nous pouvons résilier votre compte et,
                        sans limitation, vous pouvez renoncer à toute avance sur
                        votre salaire en cours, actuel ou futur. Si nous
                        résilions votre compte, vous ne pourrez pas vous
                        réinscrire ou vous inscrire sous un nouveau compte, Si
                        vous commettez une fraude ou falsifiez des informations
                        dans le cadre de votre utilisation de l’application
                        GWALA, votre compte sera résilié immédiatement et nous
                        nous réservons le droit de vous tenir responsable de
                        tous les dommages causés par votre conduite, d'intenter
                        une action en justice. Pour accéder à nos services, vous
                        devez vous identifier à l'aide de votre adresse mail ou
                        votre numéro de téléphone et d’un mot de passe qui sont
                        strictement personnel, ainsi toute divulgation est
                        interdite . Dans le cas contraire, vous serez tenu seul
                        responsable. L’Utilisateur pourra également solliciter
                        la suppression de son compte à l’adresse suivante :
                        simo@gwala.co Celle-ci sera effective dans un délai
                        raisonnable. Toute suppression de compte, quel qu’en
                        soit le motif, engendre la suppression pure et simple de
                        toutes les informations personnelles de l’Utilisateur.
                    </Typography>
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" fontWeight={600} mb={2}>
                        8-MOYENS DE COMMUNICATION :
                    </Typography>
                    <Typography>
                        Dès la finalisation de la création de votre compte ,
                        vous consentez et acceptez expressément de recevoir des
                        communications de la part de notre équipe , par e-mail,
                        message texte, appels et notifications sur votre
                        téléphone et au numéro de téléphone cellulaire que vous
                        nous avez fourni.
                    </Typography>
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" fontWeight={600} mb={2}>
                        9-POLITIQUE DE PROTECTION DES DONNEES À CARACTERE
                        PERSONNEL :
                    </Typography>
                    <Typography>
                        Dans le cadre de l’exploitation de l’application
                        l’utilisateur consent et accepte que GWALA collecte et
                        traite ses données à caractère personnel en concordance
                        avec la Loi n° 09-08 relative à la protection des
                        personnes physiques à l’égard du traitement des données
                        à caractère personnel. Vos données ne font pas l’objet
                        d’un transfert à l’étranger et GWALA est tenu de
                        respecter le secret professionnel. À cet effet, GWALA ne
                        procèdera en aucun cas à la vente de vos coordonnées ni
                        à leur communication. Ce traitement a été notifié et
                        autorisé par la Commission Nationale de contrôle de
                        protection des Données à caractère Personnel (la CNDP)
                        sous le numéro .... Conformément à la Loi n° 09-08
                        relative à la protection des personnes physiques à
                        l’égard du traitement des données à caractère personnel,
                        l'Utilisateur dispose à tout moment d’un droit d’accès,
                        de rectification, de suppression et d’opposition pour
                        motif légitime en adressant un courrier électronique à
                        :simo@gwala.co ou le numéro 0641377536 GWALA se dégage
                        de toute responsabilité résultant de tout manquement de
                        sa part par rapport à la règlementation susvisée.
                    </Typography>
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" fontWeight={600} mb={2}>
                        10-LIMITATION DE RESPONSABILITÉ (GARANTIE D’EVICTION) :
                    </Typography>
                    <Typography>
                        Vous acceptez de défendre, d'indemniser et de dégager de
                        toute responsabilité GWALA et ses filiales, agents,
                        concédants de licence, gestionnaires et autres sociétés
                        affiliées, ainsi que les membres de leur personnel, les
                        responsables et les directeurs, contre toute
                        réclamation, tout dommage, toute obligation, toute
                        perte, toute responsabilité, tout coût ou toute dette et
                        toute dépense (y compris, mais sans s'y limiter, les
                        honoraires d'avocat) découlant de ou en rapport avec
                        :-Votre utilisation et votre accès à l’application , y
                        compris toute donnée ou tout contenu transmis ou reçu
                        par vous ; -Votre violation de toute condition du
                        présent accord et de ses mises à jours futurs; -La
                        violation de tout droit d'un tiers, y compris, mais sans
                        s'y limiter, le droit à la vie privée ou les droits de
                        propriété intellectuelle ; -Votre violation de toute
                        loi, règle ou réglementation applicable ; -Les
                        informations personnelles ou toute autre information ou
                        contenu soumis via votre compte sur l’application GWALA,
                        y compris, sans s'y limiter, les informations
                        trompeuses, fausses ou inexactes ;une faute négligente
                        ou délibérée ; -L’accès et l'utilisation du compte par
                        toute autre partie avec votre nom d'utilisateur unique,
                        votre mot de passe ou tout autre code de sécurité
                        approprié.
                    </Typography>
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" fontWeight={600} mb={2}>
                        11-RESILIATION DES CGU :
                    </Typography>
                    <Typography>
                        La résiliation des présentes conditions d'utilisation
                        peut intervenir à tout moment, GWALA peut suspendre
                        immédiatement l'accès à l’application , sans préavis ni
                        responsabilité, si vous ne respectez pas les termes des
                        présentes conditions d'utilisation ou pour toute autre
                        raison. Nonobstant ce qui précède GWALA a le droit de
                        résilier ou de suspendre immédiatement l'un de vos mots
                        de passe ou comptes si elle considère, à sa seule
                        discrétion, que votre conduite est inacceptable ou
                        inappropriée.
                    </Typography>
                </Box>
                <Box mb={4}>
                    <Typography variant="h6" fontWeight={600} mb={2}>
                        12 -DROIT APPLICABLE ET JURIDICTION COMPÉTENTE :
                    </Typography>
                    <Typography>
                        Les présentes conditions d’utilisations sont soumises au
                        droit marocain. Tout différend découlant des présentes
                        conditions ou en relation avec celles-ci, qui ne
                        pourrait être réglé à l’amiable entre les Parties, sera
                        tranché définitivement par la juridiction compétente.
                        Les présentes Conditions générales d’utilisation peuvent
                        être modifiées à tout moment. Par conséquent, veuillez
                        les vérifier régulièrement afin d’être tenus informés
                        des mises à jour. La dernière version des présentes CGU
                        sera toujours disponible sur notre Plateforme. Toute
                        nouvelle version des présentes CGU prendra effet
                        immédiatement à la date de publication et régira toute
                        demande d’inscription passée à compter de cette date.
                        Toutes modifications des CGU postérieures à une demande,
                        n'auront pas d'impact sur cette dernière ni sur vos
                        relations avec nous, sauf si la loi en vigueur l’exige.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Privacy;
