const employeeApp = {
    appStoreLink: "https://apps.apple.com/ma/app/gwala/id6448807268",
    playStoreLink: "https://play.google.com/store/apps/details?id=co.gwala",
};
const employerApp = {
    appStoreLink: "https://apps.apple.com/ma/app/gwala-hr/id6449501232",
    playStoreLink:
        "https://play.google.com/store/apps/details?id=co.gwala.gwala_employer",
};
const merchantApp = {
    appStoreLink: "https://apps.apple.com/ma/app/gwala-merchant/id6458187192",
    playStoreLink:
        "https://play.google.com/store/apps/details?id=co.gwala.merchant",
};

export { employeeApp, employerApp, merchantApp };
