import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
    Dialog,
    Slide,
    IconButton,
    Button,
    Menu,
    Container,
    Box,
    Hidden,
    MenuItem,
} from "@mui/material";
import MobileMenu from "./MobileMenu";
import Footer from "../Footer/Footer";
import LanguageSelection from "../LanguageSelection/LanguageSelection";
import RoundButton from "../Buttons/RoundButton";
import logo from "../../assets/images/gwala_logo.png";
import { HashLink } from "react-router-hash-link";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

function Navbar({ page }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [mobileMenu, setMobileMenu] = useState(false);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);

    const handleSettingsClick = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handleOpenMenu = () => {
        setMobileMenu(true);
    };

    const handleCloseMenu = () => {
        setMobileMenu(false);
    };
    const openProductsMenu = Boolean(anchorEl1);

    const handleOpenProductsMenu = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const redirectTo = (link) => {
        navigate(`/${link}`);
        setAnchorEl1(null);
    };

    let path = window.location.pathname;
    // let pathWithId = window.location.href;

    return (
        <div>
            <Box
                sx={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#fff",
                    height: "75px",
                    zIndex: "2",
                }}
            >
                <Container
                    maxWidth="xl"
                    sx={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        {/* burger */}
                        <Hidden mdUp>
                            <IconButton
                                onClick={handleOpenMenu}
                                sx={{ paddingLeft: "0", marginLeft: "1.2rem" }}
                            >
                                <MenuOutlinedIcon />
                            </IconButton>
                        </Hidden>
                        {/* logo */}
                        <Box
                            sx={{
                                cursor: "pointer",
                                "& > img": {
                                    width: {
                                        xs: "6rem",
                                        sm: "8rem",
                                        md: "8rem",
                                        lg: "8.5rem",
                                    },
                                },
                            }}
                        >
                            <NavLink to="/">
                                <Box
                                    sx={{
                                        cursor: "pointer",
                                        "& > img": {
                                            width: "10rem",
                                        },
                                    }}
                                >
                                    <img src={logo} alt="logo" />
                                </Box>
                            </NavLink>
                        </Box>
                        {/* menu links */}
                        <Hidden mdDown>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 2.5,
                                }}
                            >
                                <Button
                                    onClick={handleOpenProductsMenu}
                                    endIcon={<KeyboardArrowDown />}
                                    sx={{
                                        textTransform: "capitalize",
                                        color: "#000000",

                                        "&.MuiButtonBase-root": {
                                            backgroundColor: "transparent",
                                        },
                                        fontWeight:
                                            path === "/salaryadvances" ||
                                            path === "/reimbursements" ||
                                            path === "/timetracking" ||
                                            path === "evouchers"
                                                ? "600"
                                                : "",
                                    }}
                                >
                                    {t("navbar.navitems.products.title")}
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl1}
                                    open={openProductsMenu}
                                    onClose={() => setAnchorEl1(null)}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                >
                                    <MenuItem
                                        selected={path === "/salaryadvances"}
                                        onClick={() =>
                                            redirectTo("salaryadvances")
                                        }
                                        sx={{ fontSize: "0.9375rem" }}
                                    >
                                        {t("navbar.navitems.products.menu1")}
                                    </MenuItem>
                                    <MenuItem
                                        selected={path === "/reimbursements"}
                                        onClick={() =>
                                            redirectTo("reimbursements")
                                        }
                                        sx={{ fontSize: "0.9375rem" }}
                                    >
                                        {t("navbar.navitems.products.menu2")}
                                    </MenuItem>
                                    <MenuItem
                                        selected={path === "/timetracking"}
                                        onClick={() =>
                                            redirectTo("timetracking")
                                        }
                                        sx={{ fontSize: "0.9375rem" }}
                                    >
                                        {t("navbar.navitems.products.menu3")}
                                    </MenuItem>
                                    <MenuItem
                                        selected={path === "/evouchers"}
                                        onClick={() => redirectTo("evouchers")}
                                        sx={{ fontSize: "0.9375rem" }}
                                    >
                                        {t("navbar.navitems.products.menu4")}
                                    </MenuItem>
                                    <MenuItem
                                        selected={path === "/edocuments"}
                                        onClick={() => redirectTo("edocuments")}
                                        sx={{ fontSize: "0.9375rem" }}
                                    >
                                        {t("navbar.navitems.products.menu5")}
                                    </MenuItem>
                                </Menu>

                                <Button
                                    variant="text"
                                    disableRipple
                                    onClick={() => {
                                        localStorage.setItem("tabIndex", 0);
                                        redirectTo("employers");
                                    }}
                                    sx={{
                                        textTransform: "capitalize",
                                        color: "#000000",
                                        "&.MuiButtonBase-root": {
                                            backgroundColor: "transparent",
                                        },
                                        fontWeight:
                                            path === "/employers" ? "600" : "",
                                    }}
                                >
                                    {t("navbar.navitems.employers")}
                                </Button>
                                <Button
                                    variant="text"
                                    disableRipple
                                    onClick={() => {
                                        localStorage.setItem("tabIndex", 1);
                                        redirectTo("employees");
                                    }}
                                    sx={{
                                        textTransform: "capitalize",
                                        color: "#000000",
                                        "&.MuiButtonBase-root": {
                                            backgroundColor: "transparent",
                                        },
                                        fontWeight:
                                            path === "/employees" ? "600" : "",
                                    }}
                                >
                                    {t("navbar.navitems.employees")}
                                </Button>
                                <Button
                                    variant="text"
                                    disableRipple
                                    onClick={() => redirectTo("merchants")}
                                    sx={{
                                        textTransform: "capitalize",
                                        color: "#000000",
                                        "&.MuiButtonBase-root": {
                                            backgroundColor: "transparent",
                                        },
                                        fontWeight:
                                            path === "/merchants" ? "600" : "",
                                    }}
                                >
                                    {t("navbar.navitems.merchants")}
                                </Button>

                                <Button
                                    variant="text"
                                    disableRipple
                                    onClick={() => redirectTo("faq")}
                                    sx={{
                                        textTransform: "capitalize",
                                        color: "#000000",
                                        "&.MuiButtonBase-root": {
                                            backgroundColor: "transparent",
                                        },
                                        fontWeight:
                                            path === "/faq" ? "600" : "",
                                    }}
                                >
                                    {t("navbar.navitems.faq")}
                                </Button>
                            </Box>
                        </Hidden>

                        {/* language */}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 2,
                            }}
                        >
                            <Hidden smDown>
                                <HashLink smooth to={"#contact-us"}>
                                    <RoundButton
                                        size={"small"}
                                        disableElevation
                                        text={t("getstarted")}
                                        variant="contained"
                                        color={"primary"}
                                    />
                                </HashLink>
                            </Hidden>
                            <Hidden smUp>
                                <Box width="3rem"/>
                            </Hidden>
                            {/* <span onClick={handleSettingsClick}>
                                <LanguageSelection />
                            </span> */}
                        </Box>
                    </Box>
                </Container>
            </Box>

            {page}

            <Footer />

            <Dialog
                open={mobileMenu}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseMenu}
            >
                <MobileMenu setMobileMenu={setMobileMenu} />
            </Dialog>
        </div>
    );
}

export default Navbar;
