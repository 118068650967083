import { Box, Typography } from "@mui/material";

const HowItWorksCard = ({ image, text }) => {
    return (
        <Box
            sx={{
                backgroundColor: "paper.main",
                padding: "8px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "10rem",
                px: 1,
            }}
        >
            <Box
                component="img"
                sx={{
                    width: "2.5rem",
                    mb: 2,
                }}
                alt="The house from the offer."
                src={image}
            />
            <Typography fontSize={"16px"} textAlign={"center"}>
                {text}
            </Typography>
        </Box>
    );
};

export default HowItWorksCard;
