import { Box, Button, Typography } from "@mui/material";
import RoundButton from "../components/Buttons/RoundButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
                my={5}
                px={2}
            >
                <Typography variant="h1" color="primary.main">
                    404
                </Typography>
                <Typography textAlign="center" mb={3} variant="h6">
                    {t("notfound.text")}
                </Typography>
                <RoundButton
                    onClick={() => navigate("/")}
                    size={"small"}
                    disableElevation
                    text={t("notfound.button")}
                    variant="contained"
                    color={"primary"}
                />
            </Box>
        </>
    );
};

export default NotFound;
