import React, { useEffect } from "react";
import {
    Container,
    Box,
    Typography,
    Avatar,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import potrait from "../assets/images/portrait.jpg";
import ContactUs from "../components/ContactUs/ContactUs";
import { useTranslation } from "react-i18next";

function FAQ() {
    const { t } = useTranslation();
    const faq = Array.from({ length: 22 }, (_, index) => ({
        q: t(`faq.item${index + 1}.q`),
        a: t(`faq.item${index + 1}.a`),
    }));

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);
    return (
        <Box>
            <Container maxWidth={"md"}>
                <Box
                    sx={{
                        backgroundColor: "background.main",
                        marginTop: "2.5rem",
                        padding: "2.5rem",
                    }}
                    mb={8}
                >
                    <Typography
                        variant="h5"
                        fontWeight={600}
                        mb={2}
                        textAlign={"center"}
                    >
                        {t("faq.title")}
                    </Typography>
                    {faq.map((item, index) => (
                        <Accordion key={index} sx={{ marginBottom: ".5rem" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>{item.q}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>{item.a}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Container>

            <ContactUs />
        </Box>
    );
}

export default FAQ;
