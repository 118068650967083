import { Box, Container, Grid, ImageListItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import RoundButton from "../components/Buttons/RoundButton";
import StepsAccordion from "../components/StepsAccordion";
import ContactUs from "../components/ContactUs/ContactUs";
import AppBanner from "../components/AppBanner/AppBanner";
import bannerimgEN from "../assets/images/merchant_banner.png";
import bannerimgFR from "../assets/images/merchant_banner_FR.png";
import section1 from "../assets/images/merchant_section1.png";
import section2 from "../assets/images/merchant_section2.png";
import section3 from "../assets/images/merchant_section3.png";
import section4 from "../assets/images/merchant_section4.png";

const Merchants = () => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);
    return (
        <Box>
            <Box
                sx={{
                    backgroundColor: "primary.dark",
                    color: "#fff",
                    overflowX: "hidden",
                    px: { xs: 1, md: 0 },
                }}
            >
                <Container maxWidth={"xl"}>
                    <Grid container spacing={2} px={2}>
                        <Grid
                            item
                            xs={0}
                            md={1}
                            sx={{
                                paddingBottom: {
                                    xs: 2,
                                    md: 10,
                                },
                            }}
                        ></Grid>
                        <Grid
                            item
                            xs={12}
                            md={5}
                            sx={{
                                paddingBottom: {
                                    xs: 2,
                                    md: 10,
                                },
                            }}
                        >
                            <Typography
                                variant="h4"
                                fontWeight={600}
                                mb={2}
                                mt={{ xs: 6, md: 10 }}
                            >
                                {t("merchants.banner.maintext")}
                            </Typography>
                            <Typography fontSize={"16px"} mb={4}>
                                {t("merchants.banner.subtext")}
                            </Typography>
                            <HashLink smooth to={"#contact-us"}>
                                <RoundButton
                                    size={"small"}
                                    disableElevation
                                    text={t("merchants.banner.button")}
                                    variant="contained"
                                    color={"paper"}
                                    sx={{ color: "primary.dark" }}
                                />
                            </HashLink>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: { xs: "center", md: "start" },
                                alignItems: "end",
                                marginTop: { xs: 1, md: 2 },
                            }}
                        >
                            <ImageListItem>
                                <img
                                    src={
                                        i18n.language === "en"
                                            ? bannerimgEN
                                            : bannerimgFR
                                    }
                                    alt="banner"
                                />
                            </ImageListItem>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Container maxWidth={"lg"}>
                <Grid
                    container
                    spacing={2}
                    mb={8}
                    px={2}
                    mt={2}
                    alignItems="center"
                >
                    <Grid
                        item
                        order={{ xs: 2, md: 1 }}
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ImageListItem sx={{ padding: 3, maxWidth: "400px" }}>
                            <img src={section1} alt="banner" />
                        </ImageListItem>
                    </Grid>
                    <Grid item order={{ xs: 1, md: 2 }} xs={12} md={6} px={2}>
                        <Typography variant="h5" fontWeight={600} mb={4}>
                            {t("merchants.section1.maintext")}
                        </Typography>
                        <Typography fontSize={"16px"} mb={4}>
                            {t("merchants.section1.subtext")}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            <Container
                maxWidth={"lg"}
                sx={{ backgroundColor: "secondary.main", borderRadius: "10px" }}
            >
                <Grid container spacing={2} mb={8} px={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" fontWeight={600} mb={4}>
                            {t("merchants.section2.maintext")}
                        </Typography>
                        <Typography fontSize={"16px"} mb={4}>
                            {t("merchants.section2.subtext")}
                        </Typography>
                        <Box mb={4}></Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "end",
                        }}
                    >
                        <ImageListItem sx={{ px: 3, maxWidth: "350px" }}>
                            <img src={section2} alt="pic" />
                        </ImageListItem>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth={"lg"}>
                <Grid container spacing={2} mb={8} px={2} alignItems="center">
                    <Grid
                        item
                        order={{ xs: 2, md: 1 }}
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ImageListItem sx={{ padding: 3, maxWidth: "500px" }}>
                            <img src={section3} alt="pic" />
                        </ImageListItem>
                    </Grid>
                    <Grid item order={{ xs: 1, md: 2 }} xs={12} md={6}>
                        <Typography variant="h5" fontWeight={600} mb={4}>
                            {t("merchants.section3.maintext")}
                        </Typography>
                        <Typography fontSize={"16px"} mb={4}>
                            {t("merchants.section3.subtext")}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            <Container
                maxWidth={"lg"}
                sx={{ backgroundColor: "secondary.main", borderRadius: "10px" }}
            >
                <Grid container spacing={2} mb={8} px={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" fontWeight={600} mb={4}>
                            {t("merchants.section4.maintext")}
                        </Typography>
                        <Typography fontSize={"16px"} mb={4}>
                            {t("merchants.section4.subtext")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "end",
                        }}
                    >
                        <ImageListItem sx={{ px: 3, maxWidth: "350px" }}>
                            <img src={section4} alt="pic" />
                        </ImageListItem>
                    </Grid>
                </Grid>
            </Container>

            <StepsAccordion />

            <ContactUs />

            <AppBanner />
        </Box>
    );
};

export default Merchants;
