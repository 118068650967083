import React, { useState } from "react";
import {
    MenuItem,
    Select,
    TextField,
    Grid,
    Box,
    Typography,
    ImageListItem,
    Container,
    Snackbar,
    Alert,
} from "@mui/material";
import RoundButton from "../Buttons/RoundButton";
import { useTranslation } from "react-i18next";
const base_url = process.env.REACT_APP_API_BASE_URL;

function ContactUs() {
    const [isLoading, setLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };
    const MAX_CHARACTERS = 300;
    let path = window.location.pathname;
    const image = path === "/merchants" ? "/form2.svg" : "/form.svg";
    const primary = path === "/merchants" ? "merchant" : "primary";

    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        fullName: "",
        company: "",
        phone: "",
        email: "",
        role: "Manager",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value.length > MAX_CHARACTERS) {
            return;
        }
        setFormData({ ...formData, [name]: value });
    };

    const isValidEmail = (email) => {
        const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
        return emailRegex.test(email);
    };

    const isValidPhone = (phone) => {
        const phoneRegex = /^\+[1-9]{1}[0-9]{1,14}$/;
        return phoneRegex.test(phone.replace(/\s/g, ""));
    };

    const handleSubmit = async () => {
        setFormError(false);
        setEmailError(false);
        setPhoneError(false);
        if (
            !formData.fullName?.trim() ||
            !formData.company?.trim() ||
            !formData.phone?.trim() ||
            !formData.email?.trim()
        ) {
            setFormError(true);
            return;
        }
        if (!isValidPhone(formData.phone)) {
            setPhoneError(true);
            return;
        }
        if (!isValidEmail(formData.email)) {
            setEmailError(true);
            return;
        }
        if (formData.message && formData.message.length > MAX_CHARACTERS) {
            setMessageError(true);
            return;
        }
        // if (formError || emailError || phoneError) return;
        try {
            setLoading(true);
            const dataToSend = { ...formData };
            if (!dataToSend.message) {
                delete dataToSend.message;
            }
            const response = await fetch(base_url + "/contact-us", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataToSend),
            });

            if (response.status === 200) {
                setSnackbarSeverity("success");
                setSnackbarMessage(t("contactus.submitSuccess"));
                setOpenSnackbar(true);
            } else {
                setSnackbarSeverity("error");
                setSnackbarMessage(t("contactus.submitError"));
                setOpenSnackbar(true);
            }
        } catch (error) {
            setSnackbarSeverity("error");
            setSnackbarMessage(t("contactus.submitError"));
            setOpenSnackbar(true);
        }
        setLoading(false);
    };

    return (
        <Box id="contact-us" mb={10}>
            <Container maxWidth={"lg"}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ImageListItem
                            sx={{ width: { xs: "350px", md: "5400px" } }}
                        >
                            <img src={image} alt="form" width={350} />
                        </ImageListItem>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box
                            sx={{ width: { xs: "90%", sm: "97%", md: "70%" } }}
                        >
                            <Typography
                                color={`${primary}.main`}
                                variant="h5"
                                fontWeight={600}
                                mb={1}
                            >
                                {t("contactus.title")}
                            </Typography>
                            <Typography
                                fontSize={"16px"}
                                lineHeight={"2rem"}
                                mb={1.5}
                            >
                                {t("contactus.note")}
                            </Typography>

                            <Box mb={1}>
                                <Typography
                                    fontSize={"16px"}
                                    lineHeight={"2rem"}
                                >
                                    {t("contactus.fullname")}
                                </Typography>
                                <TextField
                                    id="fullName"
                                    name="fullName"
                                    size="small"
                                    placeholder={t("contactus.fullname")}
                                    fullWidth
                                    variant="outlined"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    maxLength={MAX_CHARACTERS}
                                />
                            </Box>

                            <Box mb={1}>
                                <Typography
                                    fontSize={"16px"}
                                    lineHeight={"2rem"}
                                >
                                    {t("contactus.companyname")}
                                </Typography>
                                <TextField
                                    id="company"
                                    name="company"
                                    size="small"
                                    placeholder={t("contactus.companyname")}
                                    fullWidth
                                    variant="outlined"
                                    value={formData.company}
                                    onChange={handleChange}
                                    maxLength={MAX_CHARACTERS}
                                />
                            </Box>

                            <Box mb={1}>
                                <Typography
                                    fontSize={"16px"}
                                    lineHeight={"2rem"}
                                >
                                    {t("contactus.telephone")}
                                </Typography>

                                <TextField
                                    id="phone"
                                    name="phone"
                                    size="small"
                                    placeholder="+212xxxxxxxxx"
                                    fullWidth
                                    variant="outlined"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    maxLength={MAX_CHARACTERS}
                                />
                                {phoneError && (
                                    <Typography
                                        fontSize={"16px"}
                                        lineHeight={"2rem"}
                                        color="red"
                                    >
                                        {t("contactus.phoneError")}
                                    </Typography>
                                )}
                            </Box>

                            <Box mb={1}>
                                <Typography
                                    fontSize={"16px"}
                                    lineHeight={"2rem"}
                                >
                                    {t("contactus.email")}
                                </Typography>
                                <TextField
                                    id="email"
                                    name="email"
                                    size="small"
                                    placeholder={t("contactus.email")}
                                    fullWidth
                                    variant="outlined"
                                    value={formData.email}
                                    maxLength={MAX_CHARACTERS}
                                    onChange={handleChange}
                                />
                                {emailError && (
                                    <Typography
                                        fontSize={"16px"}
                                        lineHeight={"2rem"}
                                        color="red"
                                    >
                                        {t("contactus.emailError")}
                                    </Typography>
                                )}
                            </Box>

                            <Box mb={2}>
                                <Typography
                                    fontSize={"16px"}
                                    lineHeight={"2rem"}
                                >
                                    {t("contactus.iam")}
                                </Typography>
                                <Select
                                    id="role"
                                    name="role"
                                    value={formData.role}
                                    onChange={handleChange}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                >
                                    <MenuItem value="Manager">
                                        {t("contactus.manager")}
                                    </MenuItem>
                                    <MenuItem value="Employee">
                                        {t("contactus.employee")}
                                    </MenuItem>
                                    <MenuItem value="Other">
                                        {t("contactus.other")}
                                    </MenuItem>
                                </Select>
                            </Box>

                            <Box mb={1}>
                                <Typography
                                    fontSize={"16px"}
                                    lineHeight={"2rem"}
                                >
                                    {t("contactus.messageOptional")}
                                </Typography>
                                <TextField
                                    multiline
                                    minRows={2}
                                    id="message"
                                    name="message"
                                    size="small"
                                    placeholder={t("contactus.messageOptional")}
                                    fullWidth
                                    variant="outlined"
                                    value={formData.message}
                                    maxLength={MAX_CHARACTERS}
                                    onChange={handleChange}
                                    helperText={`${formData.message?.length}/${MAX_CHARACTERS} characters`}
                                />
                                {messageError && (
                                    <Typography
                                        fontSize={"16px"}
                                        lineHeight={"2rem"}
                                        color="red"
                                    >
                                        {t("contactus.messageError")}
                                    </Typography>
                                )}
                            </Box>

                            {formError && (
                                <Typography
                                    fontSize={"16px"}
                                    lineHeight={"2rem"}
                                    color="red"
                                    mb={2}
                                >
                                    {t("contactus.requiredError")}
                                </Typography>
                            )}
                            <Snackbar
                                open={openSnackbar}
                                autoHideDuration={6000}
                                onClose={handleSnackbarClose}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                <Alert
                                    onClose={handleSnackbarClose}
                                    severity={snackbarSeverity}
                                    sx={{ width: "100%" }}
                                >
                                    {snackbarMessage}
                                </Alert>
                            </Snackbar>

                            <RoundButton
                                size={"small"}
                                disableElevation
                                text={
                                    isLoading ? t("loading") : t("getstarted")
                                }
                                disable={isLoading ? true : false}
                                variant="contained"
                                color={primary}
                                sx={{ color: "white", width: "100%" }}
                                onClick={handleSubmit}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default ContactUs;
