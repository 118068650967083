import React from "react";
import {
    DialogContent,
    Button,
    Menu,
    MenuItem,
    Box,
    IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowForwardIos, Close, KeyboardArrowDown } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import RoundButton from "../Buttons/RoundButton";
import { HashLink } from "react-router-hash-link";
import logo from "../../assets/images/gwala_logo.png";
import { NavLink } from "react-router-dom";

export default function MobileMenu({ setMobileMenu }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [anchorEl1, setAnchorEl1] = React.useState(null);

    const openProductsMenu = Boolean(anchorEl1);

    const handleOpenProductsMenu = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleCloseSidebar = () => {
        setMobileMenu(false);
    };

    const redirectTo = (link) => {
        navigate(`/${link}`);
        setAnchorEl1(null);
        setMobileMenu(false);
    };

    return (
        <div>
            <DialogContent
                style={{
                    position: "fixed",
                    left: "0",
                    top: "0",
                    height: "100%",
                    width: "16rem",
                    backgroundColor: "#FFFFFF",
                    padding: "10px 15px",
                }}
            >
                <Box
                    height="85%"
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                >
                    <Box flex="1">
                        <Box
                            mb={3}
                            display="flex"
                            justifyContent="space-between"
                        >
                            <NavLink to="/">
                                <Box
                                    sx={{
                                        cursor: "pointer",
                                        "& > img": {
                                            width: {
                                                xs: "10rem",
                                            },
                                        },
                                    }}
                                >
                                    <img src={logo} alt="logo" />
                                </Box>
                            </NavLink>

                            <IconButton
                                size="small"
                                sx={{
                                    textTransform: "capitalize",
                                    color: "#000000",
                                }}
                                onClick={handleCloseSidebar}
                            >
                                <Close sx={{ color: "grey" }} />
                            </IconButton>
                        </Box>

                        <Box>
                            <Button
                                endIcon={
                                    <KeyboardArrowDown
                                        sx={{ height: "1rem", color: "grey" }}
                                    />
                                }
                                fullWidth={true}
                                style={{ justifyContent: "space-between" }}
                                sx={{
                                    textTransform: "capitalize",
                                    color: "#000000",
                                }}
                                onClick={handleOpenProductsMenu}
                            >
                                {t("navbar.navitems.products.title")}
                            </Button>
                        </Box>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl1}
                            open={openProductsMenu}
                            onClose={() => setAnchorEl1(null)}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                        >
                            <MenuItem
                                onClick={() => redirectTo("salaryadvances")}
                            >
                                {t("navbar.navitems.products.menu1")}
                            </MenuItem>
                            <MenuItem
                                onClick={() => redirectTo("reimbursements")}
                            >
                                {t("navbar.navitems.products.menu2")}
                            </MenuItem>
                            <MenuItem
                                onClick={() => redirectTo("timetracking")}
                            >
                                {t("navbar.navitems.products.menu3")}
                            </MenuItem>
                            <MenuItem onClick={() => redirectTo("evouchers")}>
                                {t("navbar.navitems.products.menu4")}
                            </MenuItem>
                            <MenuItem onClick={() => redirectTo("edocuments")}>
                                {t("navbar.navitems.products.menu5")}
                            </MenuItem>
                        </Menu>

                        <Box>
                            <Button
                                endIcon={
                                    <ArrowForwardIos
                                        sx={{ height: "1rem", color: "grey" }}
                                    />
                                }
                                fullWidth={true}
                                style={{ justifyContent: "space-between" }}
                                sx={{
                                    textTransform: "capitalize",
                                    color: "#000000",
                                }}
                                onClick={() => {
                                    localStorage.setItem("tabIndex", 0);
                                    redirectTo("employers");
                                    setMobileMenu(false);
                                }}
                            >
                                {t("navbar.navitems.employers")}
                            </Button>
                            {/* </HashLink> */}
                        </Box>
                        <Box>
                            {/* <HashLink smooth to={"/#employee"}> */}
                            <Button
                                endIcon={
                                    <ArrowForwardIos
                                        sx={{ height: "1rem", color: "grey" }}
                                    />
                                }
                                fullWidth={true}
                                style={{ justifyContent: "space-between" }}
                                sx={{
                                    textTransform: "capitalize",
                                    color: "#000000",
                                }}
                                onClick={() => {
                                    localStorage.setItem("tabIndex", 1);
                                    redirectTo("employees");
                                    setMobileMenu(false);
                                }}
                            >
                                {t("navbar.navitems.employees")}
                            </Button>
                            {/* </HashLink> */}
                        </Box>

                        <Box>
                            <Button
                                endIcon={
                                    <ArrowForwardIos
                                        sx={{ height: "1rem", color: "grey" }}
                                    />
                                }
                                fullWidth={true}
                                style={{ justifyContent: "space-between" }}
                                sx={{
                                    textTransform: "capitalize",
                                    color: "#000000",
                                }}
                                onClick={() => redirectTo("merchants")}
                            >
                                {t("navbar.navitems.merchants")}
                            </Button>
                        </Box>

                        <Box>
                            <Button
                                endIcon={
                                    <ArrowForwardIos
                                        sx={{ height: "1rem", color: "grey" }}
                                    />
                                }
                                fullWidth={true}
                                style={{ justifyContent: "space-between" }}
                                sx={{
                                    textTransform: "capitalize",
                                    color: "#000000",
                                }}
                                onClick={() => redirectTo("faq")}
                            >
                                {t("navbar.navitems.faq")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box mt={2}>
                    <HashLink smooth to="#contact-us">
                        <RoundButton
                            onClick={() => setMobileMenu(false)}
                            size={"small"}
                            disableElevation
                            text={t("getstarted")}
                            variant="contained"
                            color={"primary"}
                        />
                    </HashLink>
                </Box>
            </DialogContent>
        </div>
    );
}
