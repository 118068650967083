import { List, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { BulletPoint } from "./RefundPolicy";

const FlightsPolicy = () => {
    return (
        <>
            <Container maxWidth={"lg"}>
                <Box mb={4}>
                    <List sx={{ listStyleType: "disc", pl: 2 }}>
                        <BulletPoint
                            text={
                                "The fare includes airfare and applicable taxes. It excludes applicable airport taxes, baggage and other charges which may be payable by the airline or at certain airports. Some airlines and fare rules do not include baggage as part of the booked fares. Also, rules vary from time to time without prior notice. It is advisable to contact the airline directly or visit the airline’s website to learn more about baggage limits and addition."
                            }
                        />
                        <BulletPoint
                            text={
                                "You are required to pay prior to the confirmation of your booking."
                            }
                        />
                        <BulletPoint
                            text={
                                "There will be no refund for 'no-shows' or any partially unused flights."
                            }
                        />
                        <BulletPoint
                            text={
                                "For any information regarding your Frequent Flyer miles, please contact the airline directly."
                            }
                        />
                        <BulletPoint
                            text={
                                "Kindly ensure that you have the valid visa, immigration clearance and passport with a validity of at least 6 months."
                            }
                        />
                        <BulletPoint
                            text={
                                "To avail of infant fares, an infant must be under 24 months throughout the entire itinerary you are booking. This includes both onward and return journeys. In the event the infant is 24 months or above on the return journey, you will need to make a separate booking using child fare."
                            }
                        />
                        <BulletPoint
                            text={
                                "Infants must be accompanied by an adult at least 18 years of age. All bookings for flights are powered by Gift Management DMCC. However, Gift Management DMCC is not responsible for any schedule change by the airline after issuance of the ticket but will inform you of the same if informed by the airline. It is advisable to reconfirm your flight timings 24 hours prior to your flight departure."
                            }
                        />
                        <BulletPoint
                            text={
                                "Gwala and Gift Management DMCC reserve the right to alter any and all fees from time to time, without any prior notice."
                            }
                        />
                    </List>
                </Box>
            </Container>
            <Container maxWidth={"lg"}>
                <Box mb={4}>
                    <Typography variant="h5" fontWeight={600} mb={2}>
                        Check-in:
                    </Typography>
                    <List sx={{ listStyleType: "disc", pl: 2 }}>
                        <BulletPoint
                            text={
                                "The passenger needs to check-in at least 3 hours prior to departure for all international flights, and 2 hours prior to departure for all Domestic flights. However, we would always recommend you call the respective airline to understand their check-in policy timings."
                            }
                        />
                        <BulletPoint
                            text={
                                "As per airline rules, the standard check-in time begins 3 hours prior to departure."
                            }
                        />
                        <BulletPoint
                            text={
                                "Infants must have valid proof of age documents showing that the infant is less than 24 months old. You need to carry appropriate travel permissions (valid passport, visa, immigration clearance etc.) acquired before departure. Gwala or Gift Management DMCC is not responsible for lack of documents produced during check-in."
                            }
                        />
                    </List>
                </Box>
            </Container>
            <Container maxWidth={"lg"}>
                <Box mb={4}>
                    <Typography variant="h5" fontWeight={600} mb={2}>
                        Amendments:
                    </Typography>
                    <List sx={{ listStyleType: "disc", pl: 2 }}>
                        <BulletPoint
                            text={
                                "Gwala can assist you with amendments to most bookings. For any amendments or queries you may call Gwala 24-hour Customer Support number on (Bank’s call center number). In some cases, though, you will need to contact the airline directly."
                            }
                        />
                        <BulletPoint
                            text={
                                "Every booking made on Gwala is subject to amendment charges levied by the airline, which may vary by flight and booking class."
                            }
                        />
                        <BulletPoint
                            text={
                                "If you amend your booking, you will be charged the difference in fare, if any, and applicable when the amendment is made. However, if the new fare is lower than the original fare, the difference in the fare amount will not be refunded. The rebooking charges as applicable will be collected and charged to your Gwala Wallet."
                            }
                        />
                        <BulletPoint
                            text={
                                "In addition to the airline's amendment charges, Gwala charges an amendment handling fee of 300 DH equivalent per passenger. We will collect these charges from you when we make the changes to your travel plans. We will also collect the difference in fare, if any is applicable when the amendment is made."
                            }
                        />
                        <BulletPoint
                            text={
                                "Depending on the airline policy, some booked fares may not be amended."
                            }
                        />
                    </List>
                </Box>
            </Container>
            <Container maxWidth={"lg"}>
                <Box mb={4}>
                    <Typography variant="h5" fontWeight={600} mb={2}>
                        Cancellation and Refund Policy:
                    </Typography>
                    <List sx={{ listStyleType: "disc", pl: 2 }}>
                        <BulletPoint
                            text={
                                "For bookings made through Gwala, cancellations are allowed based on airlines cancellation policy and fare policy. For any reasons cancellations are not initiated by you, i.e. flights being grounded/cancelled/or any other unforeseen circumstances wherein you are denied travel, applicable refunds will be refunded back into your account within 45 working days. This again would be as per the discretion of Gwala)."
                            }
                        />
                        <BulletPoint
                            text={
                                "Every booking made on Gwala is subject to cancellation charges levied by the airline, which may vary by flight and booking class."
                            }
                        />
                        <BulletPoint
                            text={
                                "Some booked fares may be non-refundable as per the specific airline's policy. Partially utilized tickets as well in most cases, are non-refundable."
                            }
                        />
                        <BulletPoint
                            text={
                                "There would be a handling fee of (Amount in DH) levied per passenger per ticket for any cancellations, if there is any refund initiated by Gwala or Gift Management DMCC as an exception."
                            }
                        />
                        <BulletPoint
                            text={
                                "Only cancellation requests made through our customer support shall be entertained. Gwala or Gift Management DMCC shall not be liable to entertain any cancellation requests made through any other medium including but not limited to SMS and e-mail."
                            }
                        />
                    </List>
                </Box>
            </Container>
        </>
    );
};

export default FlightsPolicy;
