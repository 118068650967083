import { useEffect, useState } from "react";
import banner1 from "../assets/images/homebanner1.png";
import banner2 from "../assets/images/homebanner2.png";
import banner3 from "../assets/images/homebanner3.png";
import banner4 from "../assets/images/homebanner4.png";
import banner5 from "../assets/images/homebanner5.png";
import { useNavigate } from "react-router-dom";

const GwalaGallery = ({ screenSize }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const imagePaths = [banner1, banner2, banner3, banner4, banner5];
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === imagePaths.length - 1 ? 0 : prevIndex + 1
            );
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            {screenSize < 600 ? (
                <>
                    <img
                        src={imagePaths[currentImageIndex]}
                        width={400}
                        alt="banner"
                        useMap="#products-sm"
                    />
                    <map name="products-sm">
                        <area
                            shape="circle"
                            coords="270.4,308.4,17.2"
                            alt="edocuments"
                            onClick={() => {
                                navigate("/edocuments");
                            }}
                            style={{ cursor: "pointer" }}
                        />
                        <area
                            shape="circle"
                            coords="305.8,264.6,16.2"
                            alt="timetracking"
                            onClick={() => {
                                navigate("/timetracking");
                            }}
                            style={{ cursor: "pointer" }}
                        />
                        <area
                            title="2"
                            shape="circle"
                            coords="348.4,289.8,23.4"
                            alt="salaryadvance"
                            onClick={() => {
                                navigate("/salaryadvances");
                            }}
                            style={{ cursor: "pointer" }}
                        />
                        <area
                            shape="circle"
                            coords="301.6,344.4,23.4"
                            alt="bonus management"
                            onClick={() => {
                                navigate("/evouchers");
                            }}
                            style={{ cursor: "pointer" }}
                        />
                        <area
                            shape="circle"
                            coords="235,376.2,21"
                            alt="expense management"
                            onClick={() => {
                                navigate("/timetracking");
                            }}
                            style={{ cursor: "pointer" }}
                        />
                        <area
                            shape="circle"
                            coords="160.8,376.2,23.4"
                            alt="expense management"
                            onClick={() => {
                                navigate("/reimbursements");
                            }}
                            style={{ cursor: "pointer" }}
                        />
                    </map>
                </>
            ) : (
                <>
                    <img
                        src={imagePaths[currentImageIndex]}
                        width={600}
                        alt="banner"
                        useMap="#products-lg"
                    />
                    <map name="products-lg">
                        <area
                            shape="circle"
                            coords="406,462,26"
                            alt="edocuments"
                            onClick={() => {
                                navigate("/edocuments");
                            }}
                            style={{ cursor: "pointer" }}
                        />

                        <area
                            shape="circle"
                            coords="457,396,25"
                            alt="timetracking"
                            onClick={() => {
                                navigate("/timetracking");
                            }}
                            style={{ cursor: "pointer" }}
                        />
                        <area
                            title="2"
                            shape="circle"
                            coords="522,433,37"
                            alt="salaryadvance"
                            onClick={() => {
                                navigate("/salaryadvances");
                            }}
                            style={{ cursor: "pointer" }}
                        />
                        <area
                            shape="circle"
                            coords="454,517,37"
                            alt="bonus management"
                            onClick={() => {
                                navigate("/evouchers");
                            }}
                            style={{ cursor: "pointer" }}
                        />
                        <area
                            shape="circle"
                            coords="350,564,35"
                            alt="expense management"
                            onClick={() => {
                                navigate("/timetracking");
                            }}
                            style={{ cursor: "pointer" }}
                        />
                        <area
                            shape="circle"
                            coords="240,563,37"
                            alt="expense management"
                            onClick={() => {
                                navigate("/reimbursements");
                            }}
                            style={{ cursor: "pointer" }}
                        />
                    </map>
                </>
            )}
        </>
    );
};

export default GwalaGallery;
