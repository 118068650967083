import { Box, Container, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactOwlCarousel from "react-owl-carousel";
const TestimonySlider = styled(ReactOwlCarousel)(({ theme }) => ({
    "& .owl-carousel": {
        position: "relative",
        "&:hover .owl-nav": {
            opacity: 1,
        },
    },
    // ".owl-item.active.center .box": {
    //     color: "#fff",
    //     backgroundColor: "#347BF1",
    // },
    "& .owl-nav": {
        width: "100%",
        position: "absolute",
        display: "flex !important",
        top: "30%",
        justifyContent: "center",
        // transform: "TranslateY(50%)",
        // transition: "all .2s ease-in",
        marginTop: "17rem",
    },
    "& .owl-prev": {
        padding: "1rem",
        background: "#F3F4F6",
        color: "#347BF1",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "10px",
    },
    ".owl-next": {
        padding: "1rem",
        background: "#F3F4F6",
        color: "#347BF1",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "10px",
    },
    ".owl-next svg": {
        color: "#347BF1",
        height: "1.2rem",
        width: "1.2rem",
    },
    ".owl-prev svg": {
        color: "#347BF1",
        height: "1.2rem",
        width: "1.2rem",
    },
}));

const Testimonials = () => {
    const { t } = useTranslation();
    const indexArray = [1, 2, 3];

    return (
        <Container maxWidth={"lg"} sx={{ marginTop: "5rem" }}>
            <Typography
                color="primary.main"
                variant="h5"
                textAlign={"center"}
                fontWeight={600}
                mb={3}
            >
                {t("home.testimonials.title")}
            </Typography>
            <Grid container spacing={1} mb={20}>
                <Grid item xs={12}>
                    <TestimonySlider
                        items={3}
                        center={true}
                        autoplay={true}
                        autoplaySpeed={500}
                        loop={true}
                        lazyLoad={true}
                        nav={true}
                        navElement="div"
                        autoplayTimeout={4000}
                        startPosition={0}
                        navText={[
                            `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-bac4tg-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowBackIcon"><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path></svg>`,
                            `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-bac4tg-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowForwardIcon"><path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path></svg>`,
                        ]}
                        responsive={{
                            760: { items: "3" },
                            340: { items: "1" },
                        }}
                    >
                        {indexArray.map((index) => {
                            return (
                                <Box
                                    className="box"
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "16px",
                                        border: "1px solid #DFDFDF",
                                        margin: "0 20px",
                                        padding: "1rem 2rem",
                                        height: "19.5rem",
                                        transition:
                                            "background-color 0.5s ease",
                                    }}
                                >
                                    <div>
                                        <Typography
                                            mb={3}
                                            align="center"
                                            fontSize={"16px"}
                                        >
                                            {t(
                                                `home.testimonials.testimonial${index}.text`
                                            )}
                                        </Typography>
                                    </div>
                                    <Typography
                                        fontWeight={600}
                                        fontSize={"16px"}
                                    >
                                        {t(
                                            `home.testimonials.testimonial${index}.name`
                                        )}
                                    </Typography>
                                    <Typography fontSize={"16px"}>
                                        {t(
                                            `home.testimonials.testimonial${index}.occupation`
                                        )}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </TestimonySlider>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Testimonials;
