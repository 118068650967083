import { List, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { BulletPoint } from "./RefundPolicy";

const HotelsPolicy = () => {
    return (
        <>
            <Container maxWidth={"lg"}>
                <Box mb={4}>
                    <Typography variant="h5" fontWeight={600} mb={2}>
                        Booking Policy:
                    </Typography>
                    <List sx={{ listStyleType: "disc", pl: 2 }}>
                        <BulletPoint
                            text={
                                "The standard check-in time is after 14:00 hours (local time) and check-out time is 12 noon. This could vary according to season and/or city. Please check with the hotel for exact time and details."
                            }
                        />
                        <BulletPoint
                            text={
                                "An early check-in or a late check-out facility depends completely on the hotel’s room availability on that particular date. Gwala cannot guarantee anything in this regard. Please check with the hotel for further details."
                            }
                        />
                        <BulletPoint
                            text={
                                "Not all hotels include complimentary breakfast. Gwala does not take responsibility in case the hotel refuses to offer complimentary breakfast or meals."
                            }
                        />
                        <BulletPoint
                            text={
                                "As a general policy, a single room can accommodate a maximum of 2 adults and/or 2 adults and 2 children, but individual hotel policy will determine the final rules."
                            }
                        />
                    </List>
                </Box>
            </Container>
            <Container maxWidth={"lg"}>
                <Box mb={4}>
                    <Typography variant="h5" fontWeight={600} mb={2}>
                        Re-confirmation:
                    </Typography>
                    <Typography sx={{ textDecoration: "underline" }}>
                        There is no need to reconfirm the hotel booking, once
                        done. However, if you wish to do so, customer support
                        team, +212773273474 or email us at{" "}
                        <b>support@gwala.co</b>. Please do not call the hotel
                        directly to confirm.
                    </Typography>
                </Box>
            </Container>
            <Container maxWidth={"lg"}>
                <Box mb={4}>
                    <Typography variant="h5" fontWeight={600} mb={2}>
                        Amendments:
                    </Typography>
                    <List sx={{ listStyleType: "disc", pl: 2 }}>
                        <BulletPoint
                            text={
                                "Once a hotel booking is placed through Gwala, no change/amendment in the respective hotel booking dates is allowed. If you wish to change your check-in and check-out dates, a completely new hotel booking would have to be made. There will be no refund granted on the original booking."
                            }
                        />
                        <Box sx={{ textDecoration: "underline" }}>
                            <BulletPoint
                                text={
                                    "Please do not call the hotel directly for reservation changes or cancellations, as the same would not be considered."
                                }
                            />
                        </Box>
                    </List>
                </Box>
            </Container>
            <Container maxWidth={"lg"}>
                <Box mb={4}>
                    <Typography variant="h5" fontWeight={600} mb={2}>
                        Cancellation Policy:
                    </Typography>
                    <List sx={{ listStyleType: "disc", pl: 2 }}>
                        <BulletPoint
                            text={
                                "For bookings made through Gwala, cancellations are allowed based on cancellation policy and refundable policy. For any reasons not initiated by you i.e. hotels sold out and/or any other unforeseen circumstances wherein you are denied stay, applicable amount will be refunded back into your account. This again is as per the discretion of Gwala."
                            }
                        />
                    </List>
                </Box>
            </Container>
        </>
    );
};

export default HotelsPolicy;
