import { Button, styled } from '@mui/material'
import React from 'react'


const StyledButton = styled(Button)(({theme}) => ({
    borderRadius: '8px',
    padding: '.6rem 1.5rem',
    textTransform: 'none',
    fontWeight: 'bold',
    border: "1px solid #EBEBEB"
}))


const RoundButton = ({text, variant, disableElevation, size, color, sx, startIcon, progress, onClick, disable, fullWidth}) => {
  return (
    <StyledButton fullWidth={fullWidth} disabled={disable} startIcon={startIcon} onClick={onClick} sx={sx} size={size} color={color} variant={variant} disableElevation={disableElevation} >{progress || text}</StyledButton>
  )
}

export default RoundButton