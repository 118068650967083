import React from "react";
import { Grid, Box, Typography, Container, Hidden } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/gwala_logo.png";
import twitter from "../../assets/images/twitter.svg";
import linkedin from "../../assets/images/linkedin.svg";
import instagram from "../../assets/images/instagram.svg";
import google from "../../assets/images/googleplay.png";
import apple from "../../assets/images/applestore.png";
import { employeeApp } from "../../utils/links";

function Footer() {
    const { t } = useTranslation();

    return (
        <Container
            className="my-footer"
            maxWidth={"xl"}
            sx={{ borderTop: "1px solid lightgrey", marginTop: "5rem" }}
        >
            <Grid container spacing={2} p={3}>
                <Grid
                    item
                    xs={12}
                    sm={3}
                    md={2}
                    sx={{ color: "#FFFFFF", margin: "0 auto" }}
                    mb={4}
                >
                    <NavLink to="/">
                        <Box
                            sx={{
                                cursor: "pointer",
                                "& > img": {
                                    width: {
                                        xs: "6rem",
                                        sm: "8rem",
                                        md: "8rem",
                                        lg: "8.5rem",
                                    },
                                },
                            }}
                        >
                            <img src={logo} alt="logo" />
                        </Box>
                    </NavLink>
                </Grid>

                <Grid item xs={6} sm={3} md={2}>
                    <Typography fontSize={"16px"} color={"#767676"} mb={2}>
                        {t("footer.access.title")}
                    </Typography>
                    <NavLink to="/salaryadvances">
                        <Typography fontSize={"16px"} mb={1}>
                            {t("footer.access.salaryadvance")}
                        </Typography>
                    </NavLink>
                    <NavLink to="/reimbursements">
                        <Typography fontSize={"16px"} mb={1}>
                            {t("footer.access.reimbursement")}
                        </Typography>
                    </NavLink>
                    <NavLink to="/timetracking">
                        <Typography fontSize={"16px"} mb={1}>
                            {t("footer.access.timetracking")}
                        </Typography>
                    </NavLink>
                    <NavLink to="/evouchers">
                        <Typography fontSize={"16px"} mb={1}>
                            {t("footer.access.evouchers")}
                        </Typography>
                    </NavLink>
                    <NavLink to="/edocuments">
                        <Typography fontSize={"16px"} mb={1}>
                            {t("navbar.navitems.products.menu5")}
                        </Typography>
                    </NavLink>
                    <NavLink to="/employers">
                        <Typography fontSize={"16px"} mb={1}>
                            {t("footer.access.employerarea")}
                        </Typography>
                    </NavLink>
                    <NavLink to="/employees">
                        <Typography fontSize={"16px"} mb={1}>
                            {t("footer.access.employeearea")}
                        </Typography>
                    </NavLink>
                    <NavLink to="/merchants">
                        <Typography fontSize={"16px"} mb={1}>
                            {t("footer.access.merchantarea")}
                        </Typography>
                    </NavLink>
                </Grid>

                <Grid item xs={6} sm={3} md={2}>
                    <Typography fontSize={"16px"} color={"#767676"} mb={2}>
                        {t("footer.resources.title")}
                    </Typography>
                    {/* <NavLink to="/#press-kit">
                        <Typography fontSize={"16px"} mb={1}>
                            {t("footer.resources.presskit")}
                        </Typography>
                    </NavLink> */}
                    <NavLink to="/faq">
                        <Typography fontSize={"16px"} mb={1}>
                            {t("footer.resources.faq")}
                        </Typography>
                    </NavLink>
                </Grid>

                <Grid item xs={6} sm={3} md={2}>
                    <Typography fontSize={"16px"} color={"#767676"} mb={2}>
                        {t("footer.legal.title")}
                    </Typography>
                    <NavLink to="/privacy">
                        <Typography fontSize={"16px"} mb={1}>
                            {t("footer.legal.privacy")}
                        </Typography>
                    </NavLink>
                </Grid>

                <Hidden mdUp smDown>
                    <Grid item sm={3} />
                </Hidden>

                <Grid item xs={6} sm={3} md={2}>
                    <Typography fontSize={"16px"} color={"#767676"} mb={2}>
                        {t("footer.contacts.title")}
                    </Typography>
                    <Typography fontSize={"16px"} mb={1}>
                        7 Rue Hatim Al Assam, Casablanca 20250
                    </Typography>
                    <Typography fontSize={"16px"} mb={1}>
                        +212 770-752838
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "10",
                            alignItems: "center",
                        }}
                    >
                        <a
                            href="https://twitter.com/gwala_co"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={twitter} width={40} alt="twitter" />
                        </a>
                        <a
                            href="https://www.instagram.com/gwala_co/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={instagram} width={20} alt="instagram" />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/gwalaco"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={linkedin} width={40} alt="linkedin" />
                        </a>
                    </Box>
                </Grid>

                <Grid item sm={3} md={2}>
                    <Typography fontSize={"16px"} color={"#767676"} mb={2}>
                        {t("footer.download.title")}
                    </Typography>
                    <Box>
                        <a
                            href={employeeApp.appStoreLink}
                            rel="noreferrer"
                            target="_blank"
                        >
                            <img src={apple} width={130} alt="appstore" />
                        </a>
                    </Box>
                    <a
                        href={employeeApp.playStoreLink}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <img src={google} width={130} alt="google_play" />
                    </a>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                >
                    <Typography fontSize={"16px"} mb={3}>
                        2024 © Gwala Inc.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Footer;
