import { Box, Container, Typography } from "@mui/material";
import i1 from "../../assets/images/investor1.png";
import i2 from "../../assets/images/investor2.png";
import i3 from "../../assets/images/investor3.png";
import i4 from "../../assets/images/investor4.png";
import p1 from "../../assets/images/press1.png";
import p2 from "../../assets/images/press2.png";
import p3 from "../../assets/images/press3.png";
import p4 from "../../assets/images/press4.png";
import p5 from "../../assets/images/press5.png";
import m1 from "../../assets/images/partners/1.png";
import m2 from "../../assets/images/partners/2.png";
import m3 from "../../assets/images/partners/3.png";
import m4 from "../../assets/images/partners/4.png";
import m5 from "../../assets/images/partners/5.png";
import m6 from "../../assets/images/partners/6.png";
import m7 from "../../assets/images/partners/7.png";
import m8 from "../../assets/images/partners/8.png";
import m9 from "../../assets/images/partners/9.png";
import m10 from "../../assets/images/partners/10.png";
import m11 from "../../assets/images/partners/11.png";
import m12 from "../../assets/images/partners/12.png";
import m13 from "../../assets/images/partners/13.png";
import m14 from "../../assets/images/partners/14.png";
import m15 from "../../assets/images/partners/15.png";
import m16 from "../../assets/images/partners/16.png";
import m17 from "../../assets/images/partners/17.png";
import m18 from "../../assets/images/partners/18.png";
import m19 from "../../assets/images/partners/19.png";
import m20 from "../../assets/images/partners/20.png";
import m21 from "../../assets/images/partners/21.png";
import m22 from "../../assets/images/partners/22.png";
import m23 from "../../assets/images/partners/23.png";
import m24 from "../../assets/images/partners/24.png";
import m25 from "../../assets/images/partners/25.png";
import m26 from "../../assets/images/partners/26.png";

import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";

const investors = [i1, i2, i3, i4];
const press = [
    {
        image: p1,
        link: "https://www.wearetech.africa/fr/fils/tech-stars/le-marocain-mossaab-abaouz-ameliore-le-bien-etre-financier-des-employes-avec-gwala",
    },
    {
        image: p2,
        link: "https://lematin.ma/express/2023/fintech-marocaine-gwala-leve-6-millions-dh/387577.html",
    },
    {
        image: p3,
        link: "https://www.arabnews.fr/node/357426/mosa%C3%AFque",
    },
    {
        image: p4,
        link: "https://www.agenceecofin.com/entreprendre/2502-105899-a-la-tete-de-la-fintech-gwala-mossaab-abaouz-fournit-des-facilites-financieres-aux-salaries-au-maroc",
    },
    {
        image: p5,
        link: "https://afrikanheroes.com/2023/02/03/moroccan-fintech-startup-gwala-raises-pre-seed-funding/",
    },
];

const partners = [
    m1,
    m2,
    m3,
    m4,
    m5,
    m6,
    m7,
    m8,
    m9,
    m10,
    m11,
    m12,
    m13,
    m14,
    m15,
    m16,
    m17,
    m18,
    m19,
    m20,
    m21,
    m22,
    m23,
    m24,
    m25,
    m26,
];

const ImageSlider = ({ type }) => {
    const { t } = useTranslation();
    let list = [];
    let title = "";
    switch (type) {
        case "investors":
            list = investors;
            title = t("home.investors");
            break;
        case "press":
            list = press;
            title = t("home.presskit");
            break;
        case "partners":
            list = partners;
            title = t("home.partners.title");
            break;
        default:
            break;
    }
    return (
        <>
            <Box
                sx={{
                    pt: "3rem",
                    pb: "2rem",
                    mb: "2.5rem",
                    backgroundColor:
                        type === "partners" ? "secondary.main" : "#FFF",
                }}
            >
                <Box maxWidth={type === "press" ? "md" : "lg"} mx="auto">
                    <Typography
                        variant="h5"
                        textAlign={"center"}
                        fontWeight={600}
                        mb={3}
                    >
                        {title}
                    </Typography>

                    {/* <ReactOwlCarousel
                        items={4}
                        autoplay={true}
                        loop={true}
                        lazyLoad={true}
                        nav={false}
                        autoplayTimeout={3000}
                        startPosition={0}
                        responsive={{
                            760: { items: "4" },
                            340: { items: "2" },
                        }}
                    > */}
                    <Marquee autoFill speed={100}>
                        {list.map((item, index) => {
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        borderRadius: "12px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "180px",
                                        mx: { xs: 1, md: 3 },
                                    }}
                                >
                                    {type === "press" ? (
                                        <a
                                            href={item?.link}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img
                                                src={item.image}
                                                alt="press logo"
                                                style={{ width: "150px" }}
                                            />
                                        </a>
                                    ) : (
                                        <img
                                            src={item}
                                            alt={`${type} logo`}
                                            style={{ width: "180px" }}
                                        />
                                    )}
                                </Box>
                            );
                        })}
                    </Marquee>
                </Box>
            </Box>
        </>
    );
};

export default ImageSlider;
