import React, { useState } from "react";
import { Select, MenuItem, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import FR from "../../assets/images/fr.png";
import EN from "../../assets/images/gb.png";

function LanguageSelection() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState("en");

    // language change
    const handleLangChange = (lang) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    };

    return (
        <Select
            id="languageSelect"
            size="small"
            value={language}
            onChange={(e) => handleLangChange(e.target.value)}
            renderValue={() => {
                return (
                    <>
                        <img
                            src={language === "en" ? EN : FR}
                            style={{ width: "25px" }}
                            alt={"lang"}
                        />
                    </>
                );
            }}
            sx={{
                "& .MuiSelect-select": {
                    display: "flex",
                    padding: "0",
                    backgroundColor: "#fff",
                    alignItems: "center",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "0",
                    margin: "0",
                },
                "& .MuiSelect-icon": {
                    display: "none",
                },
            }}
        >
            <MenuItem
                value="en"
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
                <img src={EN} style={{ height: "16px" }} alt={"lang"} />
                <p style={{ fontSize: "14px", margin: "0" }}>
                    {t("languages.en")}
                </p>
            </MenuItem>
            <MenuItem
                value="fr"
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
                <img
                    src={FR}
                    style={{ height: "16px", margin: "0" }}
                    alt={"lang"}
                />
                <p style={{ fontSize: "14px", margin: "0" }}>
                    {t("languages.fr")}
                </p>
            </MenuItem>
        </Select>
    );
}

export default LanguageSelection;
