import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import RoundButton from "../components/Buttons/RoundButton";
import { Box, Container, Grid, ImageListItem, Typography } from "@mui/material";
import bannerimg from "../assets/images/marketwoman.png";
import v1 from "../assets/images/v1.png";
import v2 from "../assets/images/v2.png";
import v3 from "../assets/images/groupvoucher.png";
import v4 from "../assets/images/v4.png";
import ContactUs from "../components/ContactUs/ContactUs";
import { HashLink } from "react-router-hash-link";
import Features from "../components/Features/Features";

const Vouchers = () => {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <Box>
            <Box sx={{ minHeight: "25rem" }}>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={2} py={{ md: 8 }}>
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h4"
                                fontWeight={600}
                                mb={4}
                                mt={2}
                            >
                                {t("vouchers.banner.maintext")}
                            </Typography>
                            <Typography fontSize={"18px"} mb={4}>
                                {t("vouchers.banner.subtext")}
                            </Typography>

                            <HashLink smooth to={"#contact-us"}>
                                <RoundButton
                                    size={"medium"}
                                    disableElevation
                                    text={t("getstarted")}
                                    variant="contained"
                                    color={"primary"}
                                    sx={{ color: "paper" }}
                                />
                            </HashLink>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ImageListItem
                                sx={{ width: { xs: "300px", md: "500px" } }}
                            >
                                <img src={bannerimg} alt="banner" />
                            </ImageListItem>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Container maxWidth={"lg"}>
                <Grid container spacing={2} mb={{ sm: 1, md: 2 }}>
                    <Grid
                        order={{ xs: 2, md: 1 }}
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ImageListItem
                            sx={{ width: { xs: "330px", md: "400px" } }}
                        >
                            <img src={v1} alt="banner" />
                        </ImageListItem>
                    </Grid>
                    <Grid
                        order={{ xs: 1, md: 2 }}
                        item
                        xs={12}
                        md={6}
                        sx={{ display: "flex", alignItems: "center" }}
                        mb={{ xs: 7 }}
                    >
                        <Box>
                            <Typography
                                variant="h5"
                                fontWeight={600}
                                mb={2}
                                mt={7}
                            >
                                {t("vouchers.section1.maintext")}
                            </Typography>
                            <Typography fontSize={"16px"} mb={2}>
                                {t("vouchers.section1.subtext")}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Box
                sx={{ backgroundColor: "background.main", padding: "2.5rem 0" }}
                mb={{ xs: 2, md: 8 }}
            >
                <Container maxWidth={"lg"}>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ display: "flex", alignItems: "center" }}
                        >
                            <Box>
                                <Typography
                                    variant="h5"
                                    fontWeight={600}
                                    mb={2}
                                    mt={7}
                                >
                                    {t("vouchers.section2.maintext")}
                                </Typography>
                                <Typography fontSize={"16px"} mb={2}>
                                    {t("vouchers.section2.subtext")}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <ImageListItem
                                sx={{ width: { xs: "330px", md: "340px" } }}
                            >
                                <img src={v2} alt="banner" />
                            </ImageListItem>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Container maxWidth={"lg"}>
                <Grid container spacing={2} mb={{ sm: 3, md: 10 }}>
                    <Grid
                        order={{ xs: 2, md: 1 }}
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ImageListItem
                            sx={{ width: { xs: "330px", md: "340px" } }}
                        >
                            <img src={v3} alt={"pic"} />
                        </ImageListItem>
                    </Grid>
                    <Grid
                        order={{ xs: 1, md: 2 }}
                        item
                        xs={12}
                        md={6}
                        sx={{ display: "flex", alignItems: "center" }}
                        mb={{ xs: 3 }}
                    >
                        <Box>
                            <Typography
                                variant="h5"
                                fontWeight={600}
                                mb={2}
                                mt={7}
                            >
                                {t("vouchers.section3.maintext")}
                            </Typography>
                            <Typography fontSize={"16px"} mb={2}>
                                {t("vouchers.section3.subtext")}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Box
                sx={{ backgroundColor: "background.main", padding: "2.5rem 0" }}
                mb={12}
            >
                <Container maxWidth={"lg"}>
                    <Grid container spacing={2} minHeight="25rem">
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ display: "flex", alignItems: "center" }}
                        >
                            <Box>
                                <Typography
                                    variant="h5"
                                    fontWeight={600}
                                    mb={2}
                                    mt={3}
                                >
                                    {t("vouchers.section4.maintext")}
                                </Typography>
                                <Typography fontSize={"16px"} mb={2}>
                                    {t("vouchers.section4.subtext")}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <ImageListItem
                                sx={{ width: { xs: "330px", md: "480px" } }}
                            >
                                <img src={v4} alt={"pic"} />
                            </ImageListItem>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Features hidden={["evouchers", "edocs"]} />
            <ContactUs />
        </Box>
    );
};

export default Vouchers;
