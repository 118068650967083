import { Box, ImageListItem, Typography } from "@mui/material";
import React from "react";

const DomainCard = ({ maintext, subtext, image }) => {
    return (
        <Box
            px={0}
            py={2}
            borderRadius="10px"
            sx={{
                boxShadow: "rgba(0, 0, 0, 0.075) 0px 2px 4px 0px;",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <ImageListItem
                    sx={{ width: { xs: 80, md: 60 } }}
                >
                    <img src={image} alt={"pic"} />
                </ImageListItem>
            </Box>
            <Typography
                fontSize={{ xs: "13px", md: "15px" }}
                fontWeight={600}
                textAlign={"center"}
                mt={1.5}
            >
                {maintext}
            </Typography>
        </Box>
    );
};

export default DomainCard;
