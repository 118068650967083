import {
    Box,
    Button,
    Container,
    Grid,
    ImageListItem,
    Typography,
} from "@mui/material";
import { HashLink } from "react-router-hash-link";
import RoundButton from "../components/Buttons/RoundButton";
import bannerimg from "../assets/images/edocsbanner.png";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Features from "../components/Features/Features";
import ContactUs from "../components/ContactUs/ContactUs";
import edocs1 from "../assets/images/edocs1.png";
import edocs2 from "../assets/images/edocs2.png";

const Edocs = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);
    return (
        <Box>
            <Box sx={{ minHeight: "25rem" }}>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={2} py={{ md: 8 }}>
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h4"
                                fontWeight={600}
                                mb={4}
                                mt={2}
                            >
                                {t("edocs.banner.maintext")}
                            </Typography>
                            <Typography
                                fontSize={"16px"}
                                lineHeight={"2rem"}
                                mb={4}
                            >
                                {t("edocs.banner.subtext")}
                            </Typography>

                            <HashLink smooth to="#contact-us">
                                <RoundButton
                                    size={"small"}
                                    disableElevation
                                    text={t("getstarted")}
                                    variant="contained"
                                    color={"primary"}
                                    sx={{ color: "paper" }}
                                />
                            </HashLink>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <ImageListItem
                                sx={{
                                    maxWidth: "500px",
                                }}
                            >
                                <img src={bannerimg} alt="banner" />
                            </ImageListItem>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Container maxWidth={"md"}>
                <Grid container spacing={2} alignItems="center" mb={5}>
                    <Grid item xs={12} md={7}>
                        <Box>
                            <Typography
                                variant="h5"
                                fontWeight={600}
                                mb={2}
                                mt={2}
                            >
                                {t("edocs.section1.maintext")}
                            </Typography>
                            <Typography
                                fontSize={"16px"}
                                lineHeight={"1.5rem"}
                                mb={2}
                            >
                                {t("edocs.section1.subtext")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ImageListItem
                            sx={{
                                maxWidth: "400px",
                            }}
                        >
                            <img src={edocs1} alt="pic" />
                        </ImageListItem>
                    </Grid>
                </Grid>
            </Container>

            {/* <Box
                sx={{ backgroundColor: "background.main", padding: "2.5rem 0" }}
                mb={12}
            >
                <Container maxWidth={"lg"}>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <ImageListItem
                                sx={{
                                    maxWidth: "700px",
                                }}
                            >
                                <img src={edocs2} alt="pic" />
                            </ImageListItem>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ display: "flex", alignItems: "center" }}
                            mb={{ xs: 7 }}
                        >
                            <Box>
                                <Typography
                                    variant="h5"
                                    fontWeight={600}
                                    mb={2}
                                    mt={7}
                                >
                                    {t("edocs.section2.maintext")}
                                </Typography>
                                <Typography
                                    fontSize={"16px"}
                                    lineHeight={"1.5rem"}
                                    mb={2}
                                >
                                    {t("edocs.section2.subtext")}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box> */}

            <Features hidden={["reimbursements", "edocs"]} />
            <ContactUs />
        </Box>
    );
};

export default Edocs;
