import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import RoundButton from "../components/Buttons/RoundButton";
import { Box, Container, Grid, ImageListItem, Typography } from "@mui/material";
import bannerimgfr from "../assets/images/Salary Advance fr.png";
import bannerimgen from "../assets/images/Salary Advance en.png";
import sadvance from "../assets/images/SAdvance3.png";
import salary5 from "../assets/images/salary5.png";
import frame from "../assets/images/Frame.png";
import gradient from "../assets/images/gradientcircle.png";
import gradient2 from "../assets/images/gradientcircle2.png";
import ContactUs from "../components/ContactUs/ContactUs";
import Features from "../components/Features/Features";
import { HashLink } from "react-router-hash-link";

const Salary = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <Box>
            <Box sx={{ minHeight: "25rem" }}>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={2} py={{ md: 8 }}>
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h4"
                                fontWeight={600}
                                mb={4}
                                mt={2}
                            >
                                {t("salary.banner.maintext")}
                            </Typography>
                            <Typography
                                fontSize={"16px"}
                                lineHeight={"2rem"}
                                mb={4}
                            >
                                {t("salary.banner.subtext")}
                            </Typography>
                            <Box mb={4}>
                                <HashLink smooth to="#contact-us">
                                    <RoundButton
                                        size={"small"}
                                        disableElevation
                                        text={t("getstarted")}
                                        variant="contained"
                                        color={"primary"}
                                        sx={{ color: "paper" }}
                                    />
                                </HashLink>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <ImageListItem
                                sx={{
                                    left: { md: "-2rem" },
                                    maxWidth: "550px",
                                }}
                            >
                                <img
                                    src={
                                        i18n.language === "en"
                                            ? bannerimgen
                                            : bannerimgfr
                                    }
                                    alt="banner"
                                />
                            </ImageListItem>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box mt={4} sx={{ backgroundColor: "secondary.main" }}>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                fontWeight={600}
                                mt={5}
                                mb={4}
                                textAlign={"center"}
                            >
                                {t("salary.banner2.maintext")}
                            </Typography>
                            <Typography
                                fontSize={"16px"}
                                textAlign={"center"}
                                lineHeight={"2rem"}
                                mb={5}
                            >
                                {t("salary.banner2.subtext")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Container maxWidth={"md"}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        order={{ xs: 2, md: 1 }}
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ImageListItem
                            sx={{
                                maxWidth: "360px",
                            }}
                        >
                            <img src={sadvance} alt="pic" />
                        </ImageListItem>
                    </Grid>
                    <Grid
                        item
                        order={{ xs: 1, md: 2 }}
                        xs={12}
                        md={6}
                        sx={{
                            "&.MuiGrid-item": {
                                paddingLeft: { md: "5rem" },
                            },
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Box>
                            <Typography
                                variant="h5"
                                fontWeight={600}
                                mb={2}
                                mt={7}
                            >
                                {t("salary.section1.maintext")}
                            </Typography>
                            <Typography
                                fontSize={"16px"}
                                lineHeight={"1.5rem"}
                                mb={2}
                            >
                                {t("salary.section1.subtext")}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Box
                sx={{ backgroundColor: "background.main", padding: "2.5rem 0" }}
                mb={{ xs: 2, md: 12 }}
            >
                <Container maxWidth={"md"}>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ display: "flex", alignItems: "center" }}
                            mb={5}
                        >
                            <Box>
                                <Typography
                                    variant="h5"
                                    fontWeight={600}
                                    mb={2}
                                    mt={7}
                                >
                                    {t("salary.section2.maintext")}
                                </Typography>
                                <Typography
                                    fontSize={"16px"}
                                    lineHeight={"1.5rem"}
                                    mb={2}
                                >
                                    {t("salary.section2.subtext")}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <ImageListItem
                                sx={{
                                    maxWidth: "400px",
                                }}
                            >
                                <img src={frame} alt="pic" />
                            </ImageListItem>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Container maxWidth={"md"}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        order={{ xs: 2, md: 1 }}
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ImageListItem
                            sx={{
                                maxWidth: "400px",
                            }}
                        >
                            <img src={gradient} alt="pic" />
                        </ImageListItem>
                    </Grid>
                    <Grid item order={{ xs: 1, md: 2 }} xs={12} md={6} sx={{}}>
                        <Box>
                            <Typography
                                variant="h5"
                                fontWeight={600}
                                mb={2}
                                mt={7}
                            >
                                {t("salary.section3.maintext")}
                            </Typography>
                            <Typography
                                fontSize={"16px"}
                                lineHeight={"1.5rem"}
                                mb={2}
                            >
                                {t("salary.section3.subtext")}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Box
                sx={{
                    backgroundColor: "background.main",
                    padding: "2.5rem 0 .5rem 0",
                }}
                mb={{ xs: 2, md: 10 }}
            >
                <Container maxWidth={"md"}>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ display: "flex", alignItems: "center" }}
                            mb={{ xs: 7 }}
                        >
                            <Box>
                                <Typography
                                    variant="h5"
                                    fontWeight={600}
                                    mb={2}
                                    mt={7}
                                >
                                    {t("salary.section4.maintext")}
                                </Typography>
                                <Typography
                                    fontSize={"16px"}
                                    lineHeight={"1.5rem"}
                                    mb={2}
                                >
                                    {t("salary.section4.subtext")}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <ImageListItem
                                sx={{
                                    maxWidth: "400px",
                                }}
                            >
                                <img src={gradient2} alt="pic" />
                            </ImageListItem>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Container maxWidth={"md"}>
                <Grid container spacing={2} mb={4}>
                    <Grid
                        item
                        order={{ xs: 2, md: 1 }}
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ImageListItem
                            sx={{
                                maxWidth: "400px",
                            }}
                        >
                            <img src={salary5} alt="pic" />
                        </ImageListItem>
                    </Grid>
                    <Grid
                        item
                        order={{ xs: 1, md: 2 }}
                        xs={12}
                        md={6}
                        sx={{ marginTop: "2rem" }}
                    >
                        <Box>
                            <Typography
                                variant="h5"
                                fontWeight={600}
                                mb={2}
                                mt={7}
                            >
                                {t("salary.section5.maintext")}
                            </Typography>
                            <Typography
                                fontSize={"16px"}
                                lineHeight={"1.5rem"}
                                mb={4}
                            >
                                {t("salary.section5.subtext")}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Features hidden={["salaryadvances", "edocs"]} />
            <ContactUs />
        </Box>
    );
};

export default Salary;
