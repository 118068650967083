import React, { useEffect } from "react";
import {
    Container,
    Box,
    Grid,
    Typography,
    ImageListItem,
    IconButton,
    Hidden,
} from "@mui/material";
import homeaccessEN from "../assets/images/homeaccessEN.png";
import homeaccessFR from "../assets/images/homeaccessFR.png";
import systemFR from "../assets/images/system.png";
import systemEN from "../assets/images/systemFR.png";
import div1 from "../assets/images/div1.png";
import homephone from "../assets/images/homephone.png";
import edocs from "../assets/images/homeEdocs.png";
import tech from "../assets/images/domains/technology.png";
import retail from "../assets/images/domains/retail.png";
import health from "../assets/images/domains/health.png";
import industry from "../assets/images/domains/manufactoring.png";
import services from "../assets/images/domains/services.png";
import banking from "../assets/images/domains/bank.png";
import insurance from "../assets/images/domains/insurance.png";
import vector1 from "../assets/images/how-it-works/vector1.png";
import vector2 from "../assets/images/how-it-works/vector2.png";
import vector3 from "../assets/images/how-it-works/vector3.png";
import vector4 from "../assets/images/how-it-works/vector4.png";
import { useTranslation } from "react-i18next";
import RoundButton from "../components/Buttons/RoundButton";
import PropTypes from "prop-types";
import AppBanner from "../components/AppBanner/AppBanner";
import ContactUs from "../components/ContactUs/ContactUs";
import ImageSlider from "../components/carousel/ImageSlider";
import Testimonials from "../components/carousel/Testimonials";
import { HashLink } from "react-router-hash-link";
import DomainCard from "../components/DomainCard";
import GwalaGallery from "../components/GwalaGallery";
import { WhatsApp } from "@mui/icons-material";
import bg_s1 from "../assets/images/bg_s1.png";
import bg_s2 from "../assets/images/bg_s2.png";
import bg_s3 from "../assets/images/bg_s3.png";
import bg_s4 from "../assets/images/bg_s4.png";
import bg_s5 from "../assets/images/bg_s5.png";
import HowItWorksCard from "../components/HowItWorksCard";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        padding: "35px 35px 0 35px",
                        backgroundColor: "#347BF1",
                        borderRadius: "16px",
                    }}
                >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
const screenSize = window.innerWidth;

function Home() {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <Box>
            <a href="https://wa.me/212773273474">
                <IconButton
                    size="large"
                    aria-label="Contact"
                    sx={{
                        color: "primary.main",
                        backgroundColor: "secondary.main",
                        position: "fixed",
                        top: { xs: "90%", md: "50%" },
                        right: { xs: "25px", md: "10px" },
                        zIndex: 999,
                        "&:hover": {
                            backgroundColor: "primary.main",
                            color: "secondary.main",
                        },
                    }}
                >
                    <WhatsApp />
                </IconButton>
            </a>

            <Container maxWidth={"xl"}>
                <Grid container spacing={2} alignItems="center">
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "end",
                        }}
                    >
                        <GwalaGallery screenSize={screenSize} />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box mx={{ xs: 2, md: 0 }}>
                            <Typography variant="h4" fontWeight={600} mt={5}>
                                <span style={{ color: "#347BF1" }}>
                                    {t("home.banner.title1")}
                                </span>
                                {t("home.banner.title2")}
                            </Typography>
                            <Typography variant="h4" fontWeight={600} mb={4}>
                                <span style={{ color: "#347BF1" }}>
                                    {t("home.banner.title3")}
                                </span>
                                {t("home.banner.title4")}
                            </Typography>
                            <Typography
                                fontSize={"16px"}
                                lineHeight={"2rem"}
                                mb={4}
                            >
                                {t("home.banner.subtext")}
                            </Typography>
                            <HashLink smooth to="#contact-us">
                                <RoundButton
                                    size={"small"}
                                    disableElevation
                                    text={t("getstarted")}
                                    variant="contained"
                                    color={"primary"}
                                    sx={{ color: "paper", mb: { xs: 3 } }}
                                />
                            </HashLink>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <ImageSlider type="partners" />

            <Box position="relative">
                <Hidden xlDown>
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: "30%",
                            height: "400px",
                            backgroundImage: `url(${bg_s1})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "right",
                        }}
                    />
                </Hidden>

                <Container maxWidth={"lg"}>
                    <Grid
                        container
                        spacing={2}
                        px={2}
                        mb={8}
                        alignItems="center"
                    >
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <ImageListItem sx={{ width: { md: "500px" } }}>
                                <img
                                    src={
                                        i18n.language === "en"
                                            ? homeaccessEN
                                            : homeaccessFR
                                    }
                                    alt="banner"
                                />
                            </ImageListItem>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography
                                color="primary.main"
                                variant="h5"
                                fontWeight={600}
                                mb={4}
                            >
                                {t("home.section1.maintext")}
                            </Typography>
                            <Typography
                                fontSize={"16px"}
                                lineHeight={"2rem"}
                                mb={4}
                            >
                                {t("home.section1.subtext")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box position="relative">
                <Hidden xlDown>
                    <div
                        style={{
                            position: "absolute",
                            left: 0,
                            bottom: 0,
                            width: "20%",
                            height: "400px",
                            backgroundImage: `url(${bg_s2})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "left",
                        }}
                    />
                </Hidden>

                <Container
                    maxWidth={"lg"}
                    sx={{ backgroundColor: "secondary.main" }}
                >
                    <Grid
                        container
                        px={2}
                        spacing={2}
                        mb={8}
                        alignItems="center"
                    >
                        <Grid item xs={12} md={6}>
                            <Typography
                                color="primary.main"
                                variant="h5"
                                fontWeight={600}
                                my={2}
                            >
                                {t("home.section2.maintext")}
                            </Typography>
                            <Typography
                                fontSize={"16px"}
                                lineHeight={"2rem"}
                                mb={3}
                            >
                                {t("home.section2.subtext")}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "end",
                            }}
                        >
                            <Box
                                sx={{
                                    position: "relative",
                                    top: { md: "-2rem" },
                                }}
                                mb={{ xs: 5, md: 0 }}
                            >
                                <ImageListItem sx={{ width: { md: "400px" } }}>
                                    <img
                                        src={
                                            i18n.language === "en"
                                                ? systemEN
                                                : systemFR
                                        }
                                        alt="banner"
                                    />
                                </ImageListItem>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box position="relative">
                <Hidden xlDown>
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: "30%",
                            height: "400px",
                            backgroundImage: `url(${bg_s3})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "right",
                        }}
                    />
                </Hidden>

                <Container maxWidth={"lg"}>
                    <Grid
                        container
                        spacing={2}
                        px={2}
                        my={8}
                        alignItems="center"
                    >
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "end",
                            }}
                        >
                            <ImageListItem sx={{ width: { md: "400px" } }}>
                                <img src={div1} alt="banner" />
                            </ImageListItem>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography
                                color="primary.main"
                                variant="h5"
                                fontWeight={600}
                                mb={3}
                                mt={4}
                            >
                                {t("home.section3.maintext")}
                            </Typography>
                            <Typography
                                fontSize={"16px"}
                                lineHeight={"2rem"}
                                mb={3}
                            >
                                {t("home.section3.subtext")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box position="relative">
                <Hidden xlDown>
                    <div
                        style={{
                            position: "absolute",
                            left: 0,
                            bottom: 0,
                            width: "20%",
                            height: "400px",
                            backgroundImage: `url(${bg_s4})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "left",
                        }}
                    />
                </Hidden>
                <Container
                    maxWidth={"lg"}
                    sx={{ backgroundColor: "secondary.main" }}
                >
                    <Grid
                        container
                        spacing={2}
                        mt={{ xs: 0, md: 15 }}
                        mb={8}
                        px={2}
                        alignItems="center"
                    >
                        <Grid item xs={12} md={6}>
                            <Typography
                                color="primary.main"
                                variant="h5"
                                fontWeight={600}
                                mb={3}
                            >
                                {t("home.section4.maintext")}
                            </Typography>
                            <Typography
                                fontSize={"16px"}
                                lineHeight={"2rem"}
                                mb={3}
                            >
                                {t("home.section4.subtext")}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "end",
                            }}
                        >
                            <Box
                                sx={{
                                    position: "relative",
                                    top: { xs: "1.5rem", md: "-4.5rem" },
                                    height: { xs: "28rem", md: "20rem" },
                                }}
                            >
                                <ImageListItem sx={{ width: { md: "310px" } }}>
                                    <img src={homephone} alt="banner" />
                                </ImageListItem>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box position="relative">
                <Hidden xlDown>
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: "30%",
                            height: "400px",
                            backgroundImage: `url(${bg_s5})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "right",
                        }}
                    />
                </Hidden>
                <Container maxWidth={"lg"}>
                    <Grid
                        container
                        spacing={2}
                        px={2}
                        mb={8}
                        alignItems="center"
                    >
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <ImageListItem
                                sx={{
                                    width: { md: "500px" },
                                    right: { md: "-5rem" },
                                }}
                            >
                                <img src={edocs} alt="edocs" />
                            </ImageListItem>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography
                                color="primary.main"
                                variant="h5"
                                fontWeight={600}
                                mb={4}
                            >
                                {t("home.edocs.maintext")}
                            </Typography>
                            <Typography
                                fontSize={"16px"}
                                lineHeight={"2rem"}
                                mb={4}
                            >
                                {t("home.edocs.subtext")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Container maxWidth={"lg"}>
                <Box px={2}>
                    <Typography
                        color="primary.main"
                        variant="h5"
                        fontWeight={600}
                        textAlign={"center"}
                        mb={1}
                        mt={{ xs: 6, md: 12 }}
                    >
                        {t("home.section5.maintext")}
                    </Typography>

                    <Typography
                        fontSize={"16px"}
                        margin={"auto"}
                        textAlign={"center"}
                        width={{ md: "60%" }}
                        lineHeight={"1.5rem"}
                        mb={3}
                    >
                        {t("home.section5.subtext")}
                    </Typography>

                    <Grid container spacing={1} mt={3}>
                        <Grid item xs={6} sm={3}>
                            <DomainCard
                                maintext={t(
                                    "home.section5.technology.maintext"
                                )}
                                subtext={t("home.section5.technology.subtext")}
                                image={tech}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <DomainCard
                                maintext={t("home.section5.retail.maintext")}
                                subtext={t("home.section5.retail.subtext")}
                                image={retail}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <DomainCard
                                maintext={t(
                                    "home.section5.healthcare.maintext"
                                )}
                                subtext={t("home.section5.healthcare.subtext")}
                                image={health}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <DomainCard
                                maintext={t("home.section5.insurance.maintext")}
                                subtext={t("home.section5.insurance.subtext")}
                                image={insurance}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        mt={{ sm: 3 }}
                        justifyContent="center"
                    >
                        <Grid item xs={6} sm={3}>
                            <DomainCard
                                maintext={t("home.section5.services.maintext")}
                                subtext={t("home.section5.services.subtext")}
                                image={services}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <DomainCard
                                maintext={t("home.section5.banking.maintext")}
                                subtext={t("home.section5.banking.subtext")}
                                image={banking}
                            />
                        </Grid>
                        <Grid item xs={9} sm={3}>
                            <DomainCard
                                maintext={t("home.section5.industry.maintext")}
                                subtext={t("home.section5.industry.subtext")}
                                image={industry}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <Container
                maxWidth={"lg"}
                sx={{
                    backgroundColor: "secondary.main",
                    padding: "16px 20px",
                    marginTop: "4.5rem",
                    borderRadius: "15px",
                }}
            >
                <Typography
                    color="primary.main"
                    variant="h5"
                    textAlign={"center"}
                    fontWeight={600}
                    mb={3}
                >
                    {t("home.section6.title")}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <HowItWorksCard
                            image={vector1}
                            text={t("home.section6.section1")}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <HowItWorksCard
                            image={vector2}
                            text={t("home.section6.section2")}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <HowItWorksCard
                            image={vector3}
                            text={t("home.section6.section3")}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <HowItWorksCard
                            image={vector4}
                            text={t("home.section6.section4")}
                        />{" "}
                    </Grid>
                </Grid>
            </Container>

            <Testimonials />

            <ContactUs />
            <AppBanner />

            <div id="press-kit">
                <ImageSlider type="press" />
            </div>
        </Box>
    );
}

export default Home;
