import { Box, Container, Grid, Typography } from "@mui/material";
import employee from "../../assets/images/appbanner/employee_banner.png";
import employer from "../../assets/images/appbanner/employer_banner.png";
import merchant from "../../assets/images/appbanner/merchant_banner.png";
import google from "../../assets/images/googleplay.png";
import apple from "../../assets/images/applestore.png";
import { useTranslation } from "react-i18next";
import { employeeApp, employerApp, merchantApp } from "../../utils/links";

const AppBanner = () => {
    const { t } = useTranslation();
    let path = window.location.pathname;
    let primary = "secondary.main";
    let color = "inherit";
    let prefix = "employer";
    let image = employer;
    let links = employerApp;
    switch (path) {
        case "/employees":
            prefix = "employee";
            image = employee;
            links = employeeApp;
            break;
        case "/merchants":
            prefix = "merchant";
            image = merchant;
            primary = "primary.dark";
            color = "#FFF";
            links = merchantApp;
            break;
        default:
            break;
    }

    return (
        <Container
            maxWidth={"md"}
            sx={{
                backgroundColor: primary,
                borderRadius: "10px",
                color: color,
            }}
        >
            <Grid container spacing={2} px={2} my={5}>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        "&.MuiGrid-item": { paddingLeft: { md: "5rem" } },
                    }}
                >
                    <Typography
                        variant="h5"
                        fontWeight={600}
                        mb={2}
                        mt={{ xs: 2, md: 8 }}
                    >
                        {t(`appbanner.${prefix}.maintext`)}
                    </Typography>
                    <Typography fontSize={"16px"} lineHeight={"1.5rem"} mb={2}>
                        {t(`appbanner.${prefix}.subtext`)}
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            gap: "1rem",
                            justifyContent: "start",
                        }}
                    >
                        <a
                            href={links.appStoreLink}
                            rel="noreferrer"
                            target="_blank"
                        >
                            <img src={apple} width={130} alt="appstore" />
                        </a>

                        <a
                            href={links.playStoreLink}
                            rel="noreferrer"
                            target="_blank"
                        >
                            <img src={google} width={130} alt="google_play" />
                        </a>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "&.MuiGrid-item": { paddingTop: "2" },
                    }}
                >
                    <img src={image} width={300} alt={"pic"} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default AppBanner;
